import { IAfterGuiAttachedParams } from 'ag-grid-community'
import { CustomFilterProps, useGridFilter } from 'ag-grid-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { Box, Paper, Radio, RadioGroup, Typography } from '@mui/material'

import { Button } from '@shared/components'

const useStyles = tss.withName('RadioFilter').create(() => ({
  buttonsGroup: {
    width: '100%',
    paddingRight: '10px',
  },
  radioGroup: {
    padding: '16px 16px 0 16px',
  },
  label: {
    fontSize: '13px',
    color: '#181d1f',
    font: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },
}))

interface RadioFilterProps extends CustomFilterProps {
  defaultTo: 'true' | 'false' | 'all' // Default value for the filter, if 'all' is selected, both values will be selected
  hideSelectAll: boolean // If this is true, the select all option will not be shown
  ignoreSelectAll: boolean // If this is true, when all options are selected, the filter will be cleared (default behavior in agSetColumnFilter)
  trueLabel?: string // If custom labels are needed
  falseLabel?: string // If custom labels are needed
}

// This filter is only supposed to be used to radio filter true/false values
const RadioFilter = ({
  model,
  onModelChange,
  defaultTo,
  ignoreSelectAll,
  hideSelectAll,
  trueLabel,
  falseLabel,
}: RadioFilterProps) => {
  const { t } = useTranslation()

  if (!trueLabel) {
    trueLabel = t('common.yes')
  }
  if (!falseLabel) {
    falseLabel = t('common.no')
  }

  // Manually check if the defaultTo is a valid value. Due to how we use this filter on ag-grid, we don't have type checking when using.
  if (defaultTo !== 'true' && defaultTo !== 'false' && defaultTo !== 'all') {
    throw new Error('defaultTo must be "true", "false" or "all"')
  }

  const defaultValue = defaultTo === 'all' ? ['true', 'false'] : [defaultTo]

  const options = [
    { label: trueLabel, value: 'true' },
    { label: falseLabel, value: 'false' },
  ].sort((a, b) => a.label.localeCompare(b.label))

  const [values, setValues] = useState<string[]>(defaultValue)

  const [closeFilter, setCloseFilter] = useState<() => void>()

  const paperRef = useRef(null)

  const { classes } = useStyles()

  const handleClear = () => {
    if (
      values.length !== defaultValue.length ||
      !values.every((value) => defaultValue.includes(value))
    ) {
      setValues(defaultValue)
      if (defaultValue.length === options.length && ignoreSelectAll) {
        onModelChange(null)
      } else {
        onModelChange({
          filterType: 'set',
          values: defaultValue,
        })
      }
    }

    closeFilter?.()
  }

  const setModel = () => {
    if (!model) {
      handleClear()
      return
    }

    setValues(model.values)
  }

  const getModelAsString = useCallback((model) => {
    return options
      .filter((option) => model.values.includes(option.value))
      .map((option) => option.label)
      .join(', ')
  }, [])

  const afterGuiAttached = useCallback((params: IAfterGuiAttachedParams) => {
    setCloseFilter(() => params.hidePopup)
  }, [])

  const afterGuiDetached = useCallback(() => {
    setValues(model?.values || defaultValue)
  }, [model])

  const doesFilterPass = useCallback(() => {
    return true
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues([(event.target as HTMLInputElement).value])
  }

  const handleApply = async () => {
    if (values.length === options.length && ignoreSelectAll) {
      onModelChange(null)
    } else {
      onModelChange({
        filterType: 'set',
        values: values,
      })
    }
    closeFilter?.()
  }

  const handleAllSelected = () => {
    const allValues = options.map((option) => option.value)

    if (values.length === allValues.length) {
      setValues([options[0].value])
    } else {
      setValues(allValues)
    }
  }

  useEffect(() => {
    setModel()
  }, [model])

  useGridFilter({
    getModelAsString,
    afterGuiAttached,
    afterGuiDetached,
    doesFilterPass,
  })

  return (
    <div ref={paperRef}>
      <Paper
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <Box className={classes.radioGroup}>
          {!hideSelectAll && (
            <Box
              display="flex"
              alignItems="center"
              gap="6px"
              onClick={() => handleAllSelected()}
            >
              <Radio
                checked={values?.length === options.length}
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={
                  <CheckBox
                    style={{
                      color: '#2196f3',
                    }}
                    onClick={() => handleAllSelected()}
                  />
                }
                size="small"
                sx={{
                  padding: 0,
                }}
              />

              <Typography className={classes.label}>
                {t('common.ag-grid.select-all')}
              </Typography>
            </Box>
          )}
          {options.map((option, index) => (
            <Box
              display="flex"
              key={index}
              alignItems="center"
              gap="6px"
              onClick={() => {
                setValues([option.value])
              }}
            >
              <Radio
                checked={values.includes(option.value)}
                value={option.value}
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={
                  <CheckBox
                    style={{
                      color: '#2196f3',
                    }}
                  />
                }
                size="small"
                sx={{
                  padding: 0,
                }}
                onChange={handleChange}
              />

              <Typography className={classes.label}>{option.label}</Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          <Button
            className={classes.buttonsGroup}
            label={t('common.clear')}
            onClick={handleClear}
            color={'secondary'}
          />
          <Button
            className={classes.buttonsGroup}
            label={t('common.apply')}
            onClick={handleApply}
            color={'primary'}
          />
        </Box>
      </Paper>
    </div>
  )
}

export default RadioFilter
