import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Recurring } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'

const useStyles = tss
  .withName('MerchantAccountDetails')
  .create(({ theme }) => ({
    root: {},
  }))
export type MerchantAccountDetailsProps = {
  recurringData: Recurring | null
}
function MerchantAccountDetails({
  recurringData,
}: MerchantAccountDetailsProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const value_merchant_account_title = `${
    recurringData.product_transaction?.title
  } - ${recurringData.product_transaction?.payment_method.toUpperCase()}`

  return (
    <FieldGroupContainer
      title={t('mfe-gateway.merchant-account-details')}
      titleTestID="merchant-account-details-container-title"
    >
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.merchant-account')}
          titleTestID="merchant-account-title-label"
          value={value_merchant_account_title}
          valueTestID="merchant-account-title-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}
export default MerchantAccountDetails
