import { DateTime } from 'luxon'
import { FC, useEffect } from 'react'
import { tss } from 'tss-react/mui'

import { TextField } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import TimeSelector from './TimeSelector'

const useStyles = tss.withName('DatePicker').create(() => ({
  datePicker: {
    '.MuiPickersStaticWrapper-staticWrapperRoot': {
      padding: '8px',
    },
    '.MuiCalendarPicker-root': {
      '& .MuiIconButton-root': {
        padding: '4px',
      },
      '& .MuiTypography-caption': {
        fontSize: '0.75rem',
      },
    },
  },
  textFieldCentered: {
    width: '92%',
    marginTop: '10px',
    marginLeft: '10px',
    '.MuiInputBase-input': {
      fontSize: '0.875rem',
      color: '#495057',
      textAlign: 'center',
    },
  },
}))

interface DatePickerProps {
  timezone: string
  displayTimeSelector?: boolean
  value: DateTime
  minValue?: DateTime
  maxValue?: DateTime
  disablePast?: boolean
  disableFuture?: boolean
  label?: string
  onChange: (value: DateTime) => void
}

export const DatePicker: FC<DatePickerProps> = ({
  timezone,
  displayTimeSelector,
  value,
  minValue,
  maxValue,
  disablePast,
  disableFuture,
  label,
  onChange,
}) => {
  const { classes } = useStyles()

  useEffect(() => {
    if (minValue && value < minValue) {
      onChange(minValue.endOf('day'))
    } else if (maxValue && value > maxValue) {
      onChange(maxValue.startOf('day'))
    }
  }, [minValue, maxValue])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <TextField
        label={label}
        value={value.toFormat('MM/dd/yyyy hh:mm a')}
        className={classes.textFieldCentered}
        InputProps={{ readOnly: true }}
        size="small"
        sx={{ marginBottom: 2 }}
      />
      <StaticDatePicker
        className={classes.datePicker}
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={value}
        onChange={onChange}
        timezone={timezone}
        minDate={minValue}
        maxDate={maxValue}
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
      {displayTimeSelector && (
        <TimeSelector
          value={{
            hour: value.toFormat('h'),
            minute: value.toFormat('m'),
            period: value.toFormat('a') as 'AM' | 'PM',
          }}
          onChange={(time) => {
            let hour = parseInt(time.hour)
            if (
              (time.period === 'PM' && time.hour !== '12') ||
              (time.period === 'AM' && time.hour === '12')
            ) {
              hour = (hour + 12) % 24
            }

            const newTime = value.set({
              hour: hour,
              minute: parseInt(time.minute),
            })
            onChange(newTime)
          }}
        />
      )}
    </LocalizationProvider>
  )
}
