import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import {
  api,
  Contact,
  LogEmail,
  LogSMS,
  Paylinks,
  Transaction,
} from '@shared/api'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  ButtonBarStart,
  ButtonProps,
  HasPermission,
  Loading,
  PageLayoutContainerBlocks,
  PageLayoutContainerMainBlocks,
  PageLayoutContainerSideBlocks,
} from '@shared/components'
import {
  useAuthorization,
  useFtpPortalHubCommunication,
  useNotification,
} from '@shared/hooks'
import { checkAllPermissions, checkPermission, isInPast } from '@shared/utils'

import { CustomerDetailsView } from '@/components/customer-details-view/CustomerDetailsView'
import { EmailLogs } from '@/components/email-logs/EmailLogs'
import PaymentHistory from '@/components/payment-history/PaymentHistory'
import { SMSLogs } from '@/components/sms-logs/SMSLogs'

import { AdvancedSettings } from './components/advanced-settings/AdvancedSettings'
import { PaylinkInformation } from './components/paylink-information/PaylinkInformation'
import { DeletePaylink } from '../components/modals/delete-paylink/DeletePaylink'
import { ResendPaylink } from '../components/modals/resend-paylink/ResendPaylink'
import { PaylinkSummary } from '../components/paylink-summary/PaylinkSummary'

const useStyles = tss.withName('PaylinkView').create(() => ({
  buttonContainer: { marginRight: '8px' },
}))

export default function PaylinksView() {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { userPermissionSet } = useAuthorization()
  const { setNotification } = useNotification()

  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [paylink, setPaylink] = useState<Paylinks>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isResendModalOpen, setIsResendModalOpen] = useState(false)
  const [resendType, setResendType] = useState<'email' | 'sms' | null>(null)

  const handleCopyLink = () => {
    const baseUrl = process.env.PAYLINK_BASE_URL

    const finalUrl = `${baseUrl}?paylink_id=${paylink.id}`

    window.navigator.clipboard.writeText(finalUrl)

    setNotification({
      type: 'success',
      label: t('mfe-gateway.paylink.link-copied'),
    })
  }

  const getPaylink = async () => {
    try {
      setIsLoading(true)

      const paylink = await api.service('paylinks').get(id)

      setPaylink(paylink)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.paylink.action.view'),
      null,
      [t('common.gateway'), t('common.paylinks')],
      `/merchant/gateway/paylinks`
    )
  }, [])

  useEffect(() => {
    if (!id) {
      return
    }

    getPaylink()
  }, [id])

  if (isLoading) {
    return <Loading />
  }

  const isDeleteVisible =
    checkPermission(userPermissionSet, 'v2.paylinks.delete') && paylink.active

  const isResendEmailVisible =
    checkAllPermissions(
      userPermissionSet,
      'v2.paylinks.post',
      'v2.paylinks.post.resend'
    ) &&
    paylink.active &&
    [1, 3].includes(paylink.delivery_method) &&
    paylink.status_code === 0

  const isResendSMSVisible =
    checkAllPermissions(
      userPermissionSet,
      'v2.paylinks.post',
      'v2.paylinks.post.resend'
    ) &&
    paylink.active &&
    [2, 3].includes(paylink.delivery_method) &&
    paylink.status_code === 0

  const isCopyLinkVisible =
    paylink.active &&
    paylink.status_code === 0 &&
    paylink.expire_date >= new Date().toISOString().split('T')[0]

  const buttons: ButtonProps[] = [
    isDeleteVisible && {
      label: t('common.delete'),
      onClick: () => setIsDeleteModalOpen(true),
      guidingId: 'paylink-view-delete',
    },
    isResendSMSVisible && {
      label: t('common.resend-sms'),
      onClick: () => {
        setResendType('sms')
        setIsResendModalOpen(true)
      },
      guidingId: 'paylink-view-resend-sms',
    },
    isResendEmailVisible && {
      label: t('common.resend-email'),
      onClick: () => {
        setResendType('email')
        setIsResendModalOpen(true)
      },
      guidingId: 'paylink-view-resend-email',
    },
    isCopyLinkVisible && {
      label: t('mfe-gateway.paylink.copy-link'),
      onClick: handleCopyLink,
      guidingId: 'paylink-view-copy-link',
    },
  ].filter(Boolean)

  const isEditVisible =
    checkPermission(userPermissionSet, 'v2.paylinks.put') &&
    paylink.active &&
    paylink.status_code === 0 &&
    paylink.expire_date >= new Date().toISOString().split('T')[0]
  return (
    <HasPermission permission="v2.paylinks.get">
      <>
        <DeletePaylink
          id={id}
          open={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onSuccess={() => {
            setIsDeleteModalOpen(false)
            navigate('/merchant/gateway/paylinks')
          }}
        />
        <ResendPaylink
          open={isResendModalOpen}
          id={id}
          emailOrPhone={
            resendType === 'sms' ? paylink?.cell_phone : paylink?.email
          }
          resendType={resendType!}
          onSuccess={() => {
            getPaylink()
            setIsResendModalOpen(false)
          }}
          onClose={() => setIsResendModalOpen(false)}
        />
        {(buttons.length > 0 || isEditVisible) && (
          <ButtonBar>
            <ButtonBarStart>
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  {...button}
                  color="secondary"
                  containerClassName={classes.buttonContainer}
                />
              ))}
            </ButtonBarStart>
            {isEditVisible && (
              <ButtonBarEnd>
                <Button
                  label={t('mfe-gateway.paylink.action.edit')}
                  color="primary"
                  onClick={() =>
                    navigate(`/merchant/gateway/paylinks/${id}/edit`, {
                      state: {
                        backUrl: `/merchant/gateway/paylinks/${id}/view`,
                      },
                    })
                  }
                  guidingId="paylink-edit"
                />
              </ButtonBarEnd>
            )}
          </ButtonBar>
        )}
        <PageLayoutContainerBlocks>
          <PageLayoutContainerMainBlocks>
            <PaylinkInformation paylink={paylink} />
            <AdvancedSettings paylink={paylink} />
            <PaymentHistory
              canViewTransactions={true}
              paymentData={paylink.transactions as Transaction[]}
              guidingId="paylink"
            />
            <EmailLogs
              emailLogs={paylink.log_emails as LogEmail[]}
              guidingId="paylink"
            />
            <SMSLogs
              smsLogs={paylink.log_sms as LogSMS[]}
              guidingId="paylink"
            />
          </PageLayoutContainerMainBlocks>
          <PageLayoutContainerSideBlocks>
            <CustomerDetailsView
              customer={paylink?.contact as Contact}
              hideWalletDetails
              isWhiteBackground
              guidingId="paylink"
            />
            <PaylinkSummary paylink={paylink} />
          </PageLayoutContainerSideBlocks>
        </PageLayoutContainerBlocks>
      </>
    </HasPermission>
  )
}
