import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { api } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'

interface DeletePaylinkProps {
  open: boolean
  id: string
  onSuccess: () => void
  onClose: () => void
}

export const DeletePaylink: FC<DeletePaylinkProps> = ({
  open,
  id,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = async () => {
    try {
      setIsLoading(true)
      await api.service('paylinks').remove(id)
      setNotification({
        label: t('mfe-gateway.paylink.paylink-deleted-successfully'),
        type: 'success',
      })
      onSuccess()
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.paylink.action.delete')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
          guidingId: 'paylink-deletepaylink-cancel',
        },
        {
          label: t('mfe-gateway.paylink.yes-delete-paylink'),
          onClick: onDelete,
          color: 'primary',
          isLoading: isLoading,
          guidingId: 'paylink-deletepaylink-yes',
        },
      ]}
      onClose={onClose}
      guidingId="paylink-deletepaylink"
    >
      <Typography sx={{ fontSize: '14px' }} variant="body2">
        {`${t('common.modal.are-you-sure')} `}
        <strong>{t('mfe-gateway.paylink.delete-this-paylink')}?</strong>
        {` ${t('common.modal.this-cannot-be-undone')}`}
      </Typography>
    </ActionModal>
  )
}
