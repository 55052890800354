export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode?: string | null
) => {
  if (!phoneNumber) return

  const regex = /^(1-?)?(\d{3})[\s.-]?\(?(\d{3})\)?[\s.-]?(\d{4})(\sx\d+)?$/
  const match = phoneNumber.match(regex)

  if (match) {
    const country = match[1] || ''
    const areaCode = match[2]
    const secondPart = match[3]
    const thirdPart = match[4]
    const extension = match[5] || ''

    let formattedNumber = `${country}(${areaCode}) ${secondPart}-${thirdPart}`

    if (!/\s/.test(formattedNumber.slice(-7, -6))) {
      formattedNumber = `${country}(${areaCode}) ${secondPart}-${thirdPart}`
    }

    if (countryCode) {
      formattedNumber = `${countryCode} ${formattedNumber}`
    }

    return formattedNumber + extension
  }

  return phoneNumber
}

export const processPhone = (phone: string | null | undefined) => {
  if (!phone) {
    return phone
  }

  return phone.replace(' ', '').replace(/[()-\s]/g, '')
}
