import { ColDef } from 'ag-grid-community'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DisplayTable from '@shared/ag-grid/display-table/DisplayTable'
import { LogEmail } from '@shared/api'
import { FieldGroupContainerBlock } from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { formatDatetime } from '@shared/utils'

interface EmailLogsProps {
  emailLogs: LogEmail[]
  guidingId?: string
}

interface EmailLog {
  created_ts: number
  email: string
}

export const EmailLogs: FC<EmailLogsProps> = ({ emailLogs, guidingId }) => {
  const { t } = useTranslation()

  const { user } = useEnforceLogin()

  const [allEmails, setAllEmails] = useState<EmailLog[]>([])

  useEffect(() => {
    let allLogs = []

    emailLogs.forEach((log) => {
      allLogs.push(
        ...log.log_email_recipients?.map(({ created_ts, email }) => ({
          created_ts,
          email,
        }))
      )
    })

    allLogs = allLogs.sort((a, b) => b.created_ts - a.created_ts)

    setAllEmails(allLogs)
  }, [emailLogs])

  const columnDefs: ColDef<EmailLog>[] = useMemo(
    () => [
      {
        headerName: t('common.date'),
        field: 'created_ts',
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz)
        },
        suppressHeaderMenuButton: true,
        sortable: false,
      },
      {
        headerName: t('common.recipient'),
        field: 'email',
        suppressHeaderMenuButton: true,
        sortable: false,
      },
    ],
    []
  )

  return (
    <FieldGroupContainerBlock
      title={t('mfe-gateway.paylink.email-logs')}
      guidingId={`${guidingId}-emaillogs`}
    >
      <DisplayTable
        data={allEmails}
        columnDefs={columnDefs}
        domLayout="autoHeight"
        guidingId={guidingId}
      />
    </FieldGroupContainerBlock>
  )
}
