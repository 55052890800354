import React from 'react'

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

interface InfoIconProps extends Omit<SvgIconProps, 'color'> {
  iconColor?: string
  iconStrokeWidth?: number
  iconSize?: number
}

const InfoIcon: React.FC<InfoIconProps> = ({
  iconColor = '#4197CB',
  iconStrokeWidth = 1.5,
  iconSize,
  style,
  ...props
}) => {
  return (
    <SvgIcon
      style={{
        width: iconSize ?? 24,
        height: iconSize ?? 24,
        ...(style || {}),
      }}
      {...props}
    >
      <path
        fill="none"
        d="M11.999 8C11.861 8 11.749 8.112 11.75 8.25C11.75 8.388 11.862 8.5 12 8.5C12.138 8.5 12.25 8.388 12.25 8.25C12.25 8.112 12.138 8 11.999 8"
        stroke={iconColor}
        strokeWidth={iconStrokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z"
        stroke={iconColor}
        strokeWidth={iconStrokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M12 12V17"
        stroke={iconColor}
        strokeWidth={iconStrokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default InfoIcon
