import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import DisplayTable from '@shared/ag-grid/display-table/DisplayTable'
import { QuickInvoice } from '@shared/api'
import { FieldGroupContainer } from '@shared/components'
import { currency } from '@shared/utils'

const useStyles = tss.withName('QuickInvoiceItemsList').create(() => ({
  container: {
    padding: '16px 0',
  },
  totalLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',
    fontWeight: 'bold',
  },
}))

interface QuickInvoiceItemsListProps {
  list: QuickInvoice['item_list']
}

const QuickInvoiceItemsList: FC<QuickInvoiceItemsListProps> = ({ list }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const columnDefs = useMemo(
    () => [
      {
        headerName: t('common.item'),
        field: 'name',
        cellClass: (params) =>
          params.data.name === t('common.total') ? classes.totalLabel : '',
      },
      {
        headerName: t('common.amount'),
        field: 'amount',
        valueFormatter: (params) => currency(params.value, true),
      },
    ],
    [t, classes.totalLabel]
  )

  const dataWithTotal = useMemo(
    () => [
      ...list,
      {
        name: t('common.total'),
        amount: list.reduce((sum, item) => sum + item.amount, 0),
      },
    ],
    [list, t]
  )

  return (
    <FieldGroupContainer
      title={t('mfe-gateway.quick-invoice.invoice-items')}
      titleTestID="quick-invoice-items"
      className={classes.container}
    >
      <DisplayTable
        data={dataWithTotal}
        columnDefs={columnDefs}
        height="auto"
        guidingId="quickinvoice-items"
      />
    </FieldGroupContainer>
  )
}

export default QuickInvoiceItemsList
