import { get } from 'lodash'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, Grid } from '@mui/material'

import {
  SelectComponent as Select,
  Input,
  InputPhone,
  FieldGroupContainer,
} from '@shared/components'
import { countries, processPhone } from '@shared/utils'

export interface BillingInformationProps {
  type?: 'address' | 'billing'
  basePath?: string
  showStreet?: boolean
  showZip?: boolean
  showPhone?: boolean
  showCity?: boolean
  showState?: boolean
  showCountry?: boolean
  requireStreet?: boolean
  requireZip?: boolean
  showToggle?: boolean
  guidingId?: string
  disableCountry?: boolean
}

export const BillingInformation: FC<BillingInformationProps> = ({
  type = 'billing',
  basePath = 'billing_address',
  showStreet = true,
  showZip = true,
  showPhone = true,
  showCity = true,
  showState = true,
  showCountry = true,
  requireStreet = false,
  requireZip = false,
  showToggle = false,
  guidingId,
  disableCountry,
}) => {
  const { t } = useTranslation()
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext()

  const hasError =
    !!errors.street ||
    !!errors.city ||
    !!errors.state ||
    !!errors.postal_code ||
    !!errors.phone

  const fieldGroupTitle =
    type && type === 'address'
      ? t('common.address-information')
      : t('common.billing-information')

  const formattedGuidingId = (guidingId +=
    '-' + (type && type === 'address' ? 'address' : 'billing'))

  return (
    <Box>
      <FieldGroupContainer
        title={fieldGroupTitle}
        showToggle={showToggle}
        showContent={!showToggle || requireStreet || requireZip}
        titleTestID="billing-information-title"
        forceOpen={hasError}
        guidingId={formattedGuidingId}
      >
        <Grid container spacing={1} rowSpacing={2}>
          {!!showStreet && (
            <Grid item xs={12}>
              <Controller
                name={`${basePath}.street`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    testId="billing-street-input"
                    label={t('common.street')}
                    placeholder={t('common.street-placeholder')}
                    required={requireStreet}
                    error={
                      !!errors.street || !!get(errors, `${basePath}.street`)
                    }
                    helperText={
                      errors.street?.message.toString() ||
                      get(errors, `${basePath}.street`)?.message.toString()
                    }
                    guidingId={`${formattedGuidingId}-street`}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                      clearErrors('street')
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {!!showCity && (
            <Grid item md={6} xs={12}>
              <Controller
                name={`${basePath}.city`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    testId="billing-city-input"
                    label={t('common.city')}
                    placeholder={t('common.city-placeholder')}
                    error={!!errors.city || !!get(errors, `${basePath}.city`)}
                    helperText={
                      errors.city?.message.toString() ||
                      get(errors, `${basePath}.city`)?.message.toString()
                    }
                    guidingId={`${formattedGuidingId}-city`}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                      clearErrors('city')
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {!!showState && (
            <Grid item md={6} xs={12}>
              <Controller
                name={`${basePath}.state`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    testId="billing-state-input"
                    label={t('common.state')}
                    placeholder={t('common.state-placeholder')}
                    error={!!errors.state || !!get(errors, `${basePath}.state`)}
                    helperText={
                      errors.state?.message.toString() ||
                      get(errors, `${basePath}.state`)?.message.toString()
                    }
                    guidingId={`${formattedGuidingId}-state`}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                      clearErrors('state')
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {!!showCountry && (
            <Grid item md={6} xs={12}>
              <Controller
                name={`${basePath}.country`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    testId="billing-country-select"
                    label={t('common.country')}
                    placeholder={t('common.country-placeholder')}
                    options={countries}
                    sort
                    disabled={disableCountry}
                    style={{
                      width: '100%',
                      maxWidth: 'unset',
                      border: 'unset',
                      height: '44px',
                    }}
                    guidingId={`${formattedGuidingId}-country`}
                  />
                )}
              />
            </Grid>
          )}

          {!!showZip && (
            <Grid item md={6} xs={12}>
              <Controller
                name={`${basePath}.postal_code`}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    testId="billing-zip-input"
                    label={t('common.zip-code')}
                    placeholder={t('common.zip-code-placeholder')}
                    required={requireZip}
                    error={
                      !!errors.postal_code ||
                      !!get(errors, `${basePath}.postal_code`)
                    }
                    helperText={
                      errors.postal_code?.message.toString() ||
                      get(errors, `${basePath}.postal_code`)?.message.toString()
                    }
                    guidingId={`${formattedGuidingId}-postalcode`}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                      clearErrors('postal_code')
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {!!showPhone && (
            <Grid item xs={12}>
              <Controller
                name={`${basePath}.phone`}
                control={control}
                render={({ field }) => (
                  <InputPhone
                    {...field}
                    testId="billing-phone-input"
                    label={t('common.phone')}
                    error={!!errors.phone || !!get(errors, `${basePath}.phone`)}
                    helperText={
                      errors.phone?.message.toString() ||
                      get(errors, `${basePath}.phone`)?.message.toString()
                    }
                    onChange={(event) => {
                      field.onChange(processPhone(event.target.value))
                      clearErrors('phone')
                    }}
                    guidingId={`${formattedGuidingId}-phone`}
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </FieldGroupContainer>
    </Box>
  )
}
