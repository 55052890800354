export const countries = [
  {
    value: 'ABW',
    label: 'Aruba',
  },
  {
    value: 'AFG',
    label: 'Afghanistan',
  },
  {
    value: 'AGO',
    label: 'Angola',
  },
  {
    value: 'AIA',
    label: 'Anguilla',
  },
  {
    value: 'ALA',
    label: 'Åland Islands',
  },
  {
    value: 'ALB',
    label: 'Albania',
  },
  {
    value: 'AND',
    label: 'Andorra',
  },
  {
    value: 'ARE',
    label: 'United Arab Emirates',
  },
  {
    value: 'ARG',
    label: 'Argentina',
  },
  {
    value: 'ARM',
    label: 'Armenia',
  },
  {
    value: 'ASM',
    label: 'American Samoa',
  },
  {
    value: 'ATA',
    label: 'Antarctica',
  },
  {
    value: 'ATF',
    label: 'French Southern Territories',
  },
  {
    value: 'ATG',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'AUS',
    label: 'Australia',
  },
  {
    value: 'AUT',
    label: 'Austria',
  },
  {
    value: 'AZE',
    label: 'Azerbaijan',
  },
  {
    value: 'BDI',
    label: 'Burundi',
  },
  {
    value: 'BEL',
    label: 'Belgium',
  },
  {
    value: 'BEN',
    label: 'Benin',
  },
  {
    value: 'BES',
    label: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    value: 'BFA',
    label: 'Burkina Faso',
  },
  {
    value: 'BGD',
    label: 'Bangladesh',
  },
  {
    value: 'BGR',
    label: 'Bulgaria',
  },
  {
    value: 'BHR',
    label: 'Bahrain',
  },
  {
    value: 'BHS',
    label: 'Bahamas',
  },
  {
    value: 'BIH',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: 'BLM',
    label: 'Saint Barthélemy',
  },
  {
    value: 'BLR',
    label: 'Belarus',
  },
  {
    value: 'BLZ',
    label: 'Belize',
  },
  {
    value: 'BMU',
    label: 'Bermuda',
  },
  {
    value: 'BOL',
    label: 'Bolivia, Plurinational State of',
  },
  {
    value: 'BRA',
    label: 'Brazil',
  },
  {
    value: 'BRB',
    label: 'Barbados',
  },
  {
    value: 'BRN',
    label: 'Brunei Darussalam',
  },
  {
    value: 'BTN',
    label: 'Bhutan',
  },
  {
    value: 'BVT',
    label: 'Bouvet Island',
  },
  {
    value: 'BWA',
    label: 'Botswana',
  },
  {
    value: 'CAF',
    label: 'Central African Republic',
  },
  {
    value: 'CAN',
    label: 'Canada',
  },
  {
    value: 'CCK',
    label: 'Cocos (Keeling) Islands',
  },
  {
    value: 'CHE',
    label: 'Switzerland',
  },
  {
    value: 'CHL',
    label: 'Chile',
  },
  {
    value: 'CHN',
    label: 'China',
  },
  {
    value: 'CIV',
    label: "Côte d'Ivoire",
  },
  {
    value: 'CMR',
    label: 'Cameroon',
  },
  {
    value: 'COD',
    label: 'Congo, the Democratic Republic of the',
  },
  {
    value: 'COG',
    label: 'Congo',
  },
  {
    value: 'COK',
    label: 'Cook Islands',
  },
  {
    value: 'COL',
    label: 'Colombia',
  },
  {
    value: 'COM',
    label: 'Comoros',
  },
  {
    value: 'CPV',
    label: 'Cape Verde',
  },
  {
    value: 'CRI',
    label: 'Costa Rica',
  },
  {
    value: 'CUB',
    label: 'Cuba',
  },
  {
    value: 'CUW',
    label: 'Curaçao',
  },
  {
    value: 'CXR',
    label: 'Christmas Island',
  },
  {
    value: 'CYM',
    label: 'Cayman Islands',
  },
  {
    value: 'CYP',
    label: 'Cyprus',
  },
  {
    value: 'CZE',
    label: 'Czech Republic',
  },
  {
    value: 'DEU',
    label: 'Germany',
  },
  {
    value: 'DJI',
    label: 'Djibouti',
  },
  {
    value: 'DMA',
    label: 'Dominica',
  },
  {
    value: 'DNK',
    label: 'Denmark',
  },
  {
    value: 'DOM',
    label: 'Dominican Republic',
  },
  {
    value: 'DZA',
    label: 'Algeria',
  },
  {
    value: 'ECU',
    label: 'Ecuador',
  },
  {
    value: 'EGY',
    label: 'Egypt',
  },
  {
    value: 'ERI',
    label: 'Eritrea',
  },
  {
    value: 'ESH',
    label: 'Western Sahara',
  },
  {
    value: 'ESP',
    label: 'Spain',
  },
  {
    value: 'EST',
    label: 'Estonia',
  },
  {
    value: 'ETH',
    label: 'Ethiopia',
  },
  {
    value: 'FIN',
    label: 'Finland',
  },
  {
    value: 'FJI',
    label: 'Fiji',
  },
  {
    value: 'FLK',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    value: 'FRA',
    label: 'France',
  },
  {
    value: 'FRO',
    label: 'Faroe Islands',
  },
  {
    value: 'FSM',
    label: 'Micronesia, Federated States of',
  },
  {
    value: 'GAB',
    label: 'Gabon',
  },
  {
    value: 'GBR',
    label: 'United Kingdom',
  },
  {
    value: 'GEO',
    label: 'Georgia',
  },
  {
    value: 'GGY',
    label: 'Guernsey',
  },
  {
    value: 'GHA',
    label: 'Ghana',
  },
  {
    value: 'GIB',
    label: 'Gibraltar',
  },
  {
    value: 'GIN',
    label: 'Guinea',
  },
  {
    value: 'GLP',
    label: 'Guadeloupe',
  },
  {
    value: 'GMB',
    label: 'Gambia',
  },
  {
    value: 'GNB',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GNQ',
    label: 'Equatorial Guinea',
  },
  {
    value: 'GRC',
    label: 'Greece',
  },
  {
    value: 'GRD',
    label: 'Grenada',
  },
  {
    value: 'GRL',
    label: 'Greenland',
  },
  {
    value: 'GTM',
    label: 'Guatemala',
  },
  {
    value: 'GUF',
    label: 'French Guiana',
  },
  {
    value: 'GUM',
    label: 'Guam',
  },
  {
    value: 'GUY',
    label: 'Guyana',
  },
  {
    value: 'HKG',
    label: 'Hong Kong',
  },
  {
    value: 'HMD',
    label: 'Heard Island and McDonald Islands',
  },
  {
    value: 'HND',
    label: 'Honduras',
  },
  {
    value: 'HRV',
    label: 'Croatia',
  },
  {
    value: 'HTI',
    label: 'Haiti',
  },
  {
    value: 'HUN',
    label: 'Hungary',
  },
  {
    value: 'IDN',
    label: 'Indonesia',
  },
  {
    value: 'IMN',
    label: 'Isle of Man',
  },
  {
    value: 'IND',
    label: 'India',
  },
  {
    value: 'IOT',
    label: 'British Indian Ocean Territory',
  },
  {
    value: 'IRL',
    label: 'Ireland',
  },
  {
    value: 'IRN',
    label: 'Iran, Islamic Republic of',
  },
  {
    value: 'IRQ',
    label: 'Iraq',
  },
  {
    value: 'ISL',
    label: 'Iceland',
  },
  {
    value: 'ISR',
    label: 'Israel',
  },
  {
    value: 'ITA',
    label: 'Italy',
  },
  {
    value: 'JAM',
    label: 'Jamaica',
  },
  {
    value: 'JEY',
    label: 'Jersey',
  },
  {
    value: 'JOR',
    label: 'Jordan',
  },
  {
    value: 'JPN',
    label: 'Japan',
  },
  {
    value: 'KAZ',
    label: 'Kazakhstan',
  },
  {
    value: 'KEN',
    label: 'Kenya',
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstan',
  },
  {
    value: 'KHM',
    label: 'Cambodia',
  },
  {
    value: 'KIR',
    label: 'Kiribati',
  },
  {
    value: 'KNA',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: 'KOR',
    label: 'Korea, Republic of',
  },
  {
    value: 'KWT',
    label: 'Kuwait',
  },
  {
    value: 'LAO',
    label: "Lao People's Democratic Republic",
  },
  {
    value: 'LBN',
    label: 'Lebanon',
  },
  {
    value: 'LBR',
    label: 'Liberia',
  },
  {
    value: 'LBY',
    label: 'Libya',
  },
  {
    value: 'LCA',
    label: 'Saint Lucia',
  },
  {
    value: 'LIE',
    label: 'Liechtenstein',
  },
  {
    value: 'LKA',
    label: 'Sri Lanka',
  },
  {
    value: 'LSO',
    label: 'Lesotho',
  },
  {
    value: 'LTU',
    label: 'Lithuania',
  },
  {
    value: 'LUX',
    label: 'Luxembourg',
  },
  {
    value: 'LVA',
    label: 'Latvia',
  },
  {
    value: 'MAC',
    label: 'Macao',
  },
  {
    value: 'MAF',
    label: 'Saint Martin (French part)',
  },
  {
    value: 'MAR',
    label: 'Morocco',
  },
  {
    value: 'MCO',
    label: 'Monaco',
  },
  {
    value: 'MDA',
    label: 'Moldova, Republic of',
  },
  {
    value: 'MDG',
    label: 'Madagascar',
  },
  {
    value: 'MDV',
    label: 'Maldives',
  },
  {
    value: 'MEX',
    label: 'Mexico',
  },
  {
    value: 'MHL',
    label: 'Marshall Islands',
  },
  {
    value: 'MKD',
    label: 'Macedonia, the former Yugoslav Republic of',
  },
  {
    value: 'MLI',
    label: 'Mali',
  },
  {
    value: 'MLT',
    label: 'Malta',
  },
  {
    value: 'MMR',
    label: 'Myanmar',
  },
  {
    value: 'MNE',
    label: 'Montenegro',
  },
  {
    value: 'MNG',
    label: 'Mongolia',
  },
  {
    value: 'MNP',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'MOZ',
    label: 'Mozambique',
  },
  {
    value: 'MRT',
    label: 'Mauritania',
  },
  {
    value: 'MSR',
    label: 'Montserrat',
  },
  {
    value: 'MTQ',
    label: 'Martinique',
  },
  {
    value: 'MUS',
    label: 'Mauritius',
  },
  {
    value: 'MWI',
    label: 'Malawi',
  },
  {
    value: 'MYS',
    label: 'Malaysia',
  },
  {
    value: 'MYT',
    label: 'Mayotte',
  },
  {
    value: 'NAM',
    label: 'Namibia',
  },
  {
    value: 'NCL',
    label: 'New Caledonia',
  },
  {
    value: 'NER',
    label: 'Niger',
  },
  {
    value: 'NFK',
    label: 'Norfolk Island',
  },
  {
    value: 'NGA',
    label: 'Nigeria',
  },
  {
    value: 'NIC',
    label: 'Nicaragua',
  },
  {
    value: 'NIU',
    label: 'Niue',
  },
  {
    value: 'NLD',
    label: 'Netherlands',
  },
  {
    value: 'NOR',
    label: 'Norway',
  },
  {
    value: 'NPL',
    label: 'Nepal',
  },
  {
    value: 'NRU',
    label: 'Nauru',
  },
  {
    value: 'NZL',
    label: 'New Zealand',
  },
  {
    value: 'OMN',
    label: 'Oman',
  },
  {
    value: 'PAK',
    label: 'Pakistan',
  },
  {
    value: 'PAN',
    label: 'Panama',
  },
  {
    value: 'PCN',
    label: 'Pitcairn',
  },
  {
    value: 'PER',
    label: 'Peru',
  },
  {
    value: 'PHL',
    label: 'Philippines',
  },
  {
    value: 'PLW',
    label: 'Palau',
  },
  {
    value: 'PNG',
    label: 'Papua New Guinea',
  },
  {
    value: 'POL',
    label: 'Poland',
  },
  {
    value: 'PRI',
    label: 'Puerto Rico',
  },
  {
    value: 'PRK',
    label: "Korea, Democratic People's Republic of",
  },
  {
    value: 'PRT',
    label: 'Portugal',
  },
  {
    value: 'PRY',
    label: 'Paraguay',
  },
  {
    value: 'PSE',
    label: 'Palestinian Territory, Occupied',
  },
  {
    value: 'PYF',
    label: 'French Polynesia',
  },
  {
    value: 'QAT',
    label: 'Qatar',
  },
  {
    value: 'REU',
    label: 'Réunion',
  },
  {
    value: 'ROU',
    label: 'Romania',
  },
  {
    value: 'RUS',
    label: 'Russian Federation',
  },
  {
    value: 'RWA',
    label: 'Rwanda',
  },
  {
    value: 'SAU',
    label: 'Saudi Arabia',
  },
  {
    value: 'SDN',
    label: 'Sudan',
  },
  {
    value: 'SEN',
    label: 'Senegal',
  },
  {
    value: 'SGP',
    label: 'Singapore',
  },
  {
    value: 'SGS',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: 'SHN',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    value: 'SJM',
    label: 'Svalbard and Jan Mayen',
  },
  {
    value: 'SLB',
    label: 'Solomon Islands',
  },
  {
    value: 'SLE',
    label: 'Sierra Leone',
  },
  {
    value: 'SLV',
    label: 'El Salvador',
  },
  {
    value: 'SMR',
    label: 'San Marino',
  },
  {
    value: 'SOM',
    label: 'Somalia',
  },
  {
    value: 'SPM',
    label: 'Saint Pierre and Miquelon',
  },
  {
    value: 'SRB',
    label: 'Serbia',
  },
  {
    value: 'SSD',
    label: 'South Sudan',
  },
  {
    value: 'STP',
    label: 'Sao Tome and Principe',
  },
  {
    value: 'SUR',
    label: 'Suriname',
  },
  {
    value: 'SVK',
    label: 'Slovakia',
  },
  {
    value: 'SVN',
    label: 'Slovenia',
  },
  {
    value: 'SWE',
    label: 'Sweden',
  },
  {
    value: 'SWZ',
    label: 'Swaziland',
  },
  {
    value: 'SXM',
    label: 'Sint Maarten (Dutch part)',
  },
  {
    value: 'SYC',
    label: 'Seychelles',
  },
  {
    value: 'SYR',
    label: 'Syrian Arab Republic',
  },
  {
    value: 'TCA',
    label: 'Turks and Caicos Islands',
  },
  {
    value: 'TCD',
    label: 'Chad',
  },
  {
    value: 'TGO',
    label: 'Togo',
  },
  {
    value: 'THA',
    label: 'Thailand',
  },
  {
    value: 'TJK',
    label: 'Tajikistan',
  },
  {
    value: 'TKL',
    label: 'Tokelau',
  },
  {
    value: 'TKM',
    label: 'Turkmenistan',
  },
  {
    value: 'TLS',
    label: 'Timor-Leste',
  },
  {
    value: 'TON',
    label: 'Tonga',
  },
  {
    value: 'TTO',
    label: 'Trinidad and Tobago',
  },
  {
    value: 'TUN',
    label: 'Tunisia',
  },
  {
    value: 'TUR',
    label: 'Turkey',
  },
  {
    value: 'TUV',
    label: 'Tuvalu',
  },
  {
    value: 'TWN',
    label: 'Taiwan, Province of China',
  },
  {
    value: 'TZA',
    label: 'Tanzania, United Republic of',
  },
  {
    value: 'UGA',
    label: 'Uganda',
  },
  {
    value: 'UKR',
    label: 'Ukraine',
  },
  {
    value: 'UMI',
    label: 'United States Minor Outlying Islands',
  },
  {
    value: 'URY',
    label: 'Uruguay',
  },
  {
    value: 'USA',
    label: 'United States of America',
  },
  {
    value: 'UZB',
    label: 'Uzbekistan',
  },
  {
    value: 'VAT',
    label: 'Holy See (Vatican City State)',
  },
  {
    value: 'VCT',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'VEN',
    label: 'Venezuela, Bolivarian Republic of',
  },
  {
    value: 'VGB',
    label: 'Virgin Islands, British',
  },
  {
    value: 'VIR',
    label: 'Virgin Islands, U.S.',
  },
  {
    value: 'VNM',
    label: 'Viet Nam',
  },
  {
    value: 'VUT',
    label: 'Vanuatu',
  },
  {
    value: 'WLF',
    label: 'Wallis and Futuna',
  },
  {
    value: 'WSM',
    label: 'Samoa',
  },
  {
    value: 'YEM',
    label: 'Yemen',
  },
  {
    value: 'ZAF',
    label: 'South Africa',
  },
  {
    value: 'ZMB',
    label: 'Zambia',
  },
  {
    value: 'ZWE',
    label: 'Zimbabwe',
  },
]

const alpha2map = {
  AF: 'AFG',
  AL: 'ALB',
  DZ: 'DZA',
  AS: 'ASM',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AQ: 'ATA',
  AG: 'ATG',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BQ: 'BES',
  BA: 'BIH',
  BW: 'BWA',
  BV: 'BVT',
  BR: 'BRA',
  IO: 'IOT',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  CV: 'CPV',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  CN: 'CHN',
  CX: 'CXR',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CD: 'COD',
  CG: 'COG',
  CK: 'COK',
  CR: 'CRI',
  HR: 'HRV',
  CU: 'CUB',
  CW: 'CUW',
  CY: 'CYP',
  CZ: 'CZE',
  CI: 'CIV',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  SZ: 'SWZ',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  GF: 'GUF',
  PF: 'PYF',
  TF: 'ATF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GU: 'GUM',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HM: 'HMD',
  VA: 'VAT',
  HN: 'HND',
  HK: 'HKG',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MO: 'MAC',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MX: 'MEX',
  FM: 'FSM',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  NU: 'NIU',
  NF: 'NFK',
  MK: 'MKD',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PS: 'PSE',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  RE: 'REU',
  BL: 'BLM',
  SH: 'SHN',
  KN: 'KNA',
  LC: 'LCA',
  MF: 'MAF',
  PM: 'SPM',
  VC: 'VCT',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SX: 'SXM',
  SK: 'SVK',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  GS: 'SGS',
  SS: 'SSD',
  ES: 'ESP',
  LK: 'LKA',
  SD: 'SDN',
  SR: 'SUR',
  SJ: 'SJM',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TK: 'TKL',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  AE: 'ARE',
  GB: 'GBR',
  UM: 'UMI',
  US: 'USA',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VE: 'VEN',
  VN: 'VNM',
  VG: 'VGB',
  VI: 'VIR',
  WF: 'WLF',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
  AX: 'ALA',
}

export const getCountryById = (id: string) => {
  const country = countries.find((country) => country.value === id)
  return country ? country.label : '-'
}

export const getCountryByIdAlpha2 = (id: string) => {
  let alpha3 = alpha2map[id]
  const country = countries.find((country) => country.value === alpha3)
  return country ? country.label : '-'
}

export const getAlpha3FromAlpha2 = (code: string) => {
  return alpha2map[code] || ''
}
