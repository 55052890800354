import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Box, CircularProgress, AppBar } from '@mui/material'

import {
  FeathersError,
  MerchantAccount,
  TerminalTransactionType,
  TokenTransactionType,
  TransactionType,
  api,
  Token,
  Contact,
  TransactionData,
} from '@shared/api'
import { getCreditCardType } from '@shared/api/src/utils/transactions/creditCardDetector'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutContainerSide,
  PageLayoutDivider,
} from '@shared/components'
import {
  useEnforceLogin,
  useLocations,
  useNotification,
  useSub,
} from '@shared/hooks'
import {
  submitKeyedTransaction,
  submitTerminalTransaction,
  submitTokenTransaction,
} from '@shared/services/transactions'
import { ProcessMethod } from '@shared/types'
import {
  preprocessTransactionData,
  getVirtualTerminalMerchantAccounts,
  calculateInitialVirtualTerminalValues,
  billingAddressErrorConverter,
  errorConverter,
  getDefaultPaymentDetails,
  is_valid_luhn,
  apiFieldNameToUpperCase,
  getDefaultCountry,
} from '@shared/utils'

import { BillingInformation } from '@/components/billing-information/BillingInformation'
import {
  CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT,
  CUSTOMER_DETAILS_WALLET_CHANGE_EVENT,
  CustomerDetails,
} from '@/components/customer-details/CustomerDetails'
import OptionalInformation from '@/components/optional-information/OptionalInformation'
import {
  PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT,
  PaymentAccountDetails,
} from '@/components/payment-account-details/PaymentAccountDetails'
import {
  TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT,
  TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT,
  TransactionInformation,
} from '@/components/transaction-information/TransactionInformation'
import { VirtualTerminalUnavailable } from '@/components/virtual-terminal-unavailable/VirtualTerminalUnavailable'

import { TransactionSummary } from '../transaction-summary/TransactionSummary'

const useStyles = tss.withName('VirtualTerminal').create({
  submitButton: { marginRight: '10px' },
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    height: '100vh',
    background: 'white',
    width: '100%',
    position: 'absolute',
    zIndex: 100,
  },
})

const buildSchema = (
  t: (text: string) => string,
  merchantAccount: MerchantAccount,
  processMethod: ProcessMethod,
  selectedWallet?: Token,
  transactionType?: TransactionType
) => {
  if (!merchantAccount) return yup.object().shape({})

  const hasSubtotal =
    !!merchantAccount?.surcharge ||
    !!merchantAccount?.vt_enable_sales_tax ||
    !!merchantAccount?.vt_enable_tip

  const schema = yup.object().shape({
    notification_email_address: yup
      .string()
      .email(t('common.validations.invalid-email'))
      .test('valid-email', t('common.validations.invalid-email'), (value) => {
        if (!value) return true
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      }),
    subtotal_amount: yup.string().when([], {
      is: () => transactionType !== 'avsonly',
      then: (schema) =>
        schema
          .required(
            hasSubtotal
              ? t(
                  'mfe-gateway.validations.transaction.subtotal-amount-is-required'
                )
              : t(
                  'mfe-gateway.validations.transaction.transaction-amount-is-required'
                )
          )
          .test(
            'is-positive',
            t(
              hasSubtotal
                ? 'mfe-gateway.validations.transaction.subtotal-amount-must-be-greater-than-zero'
                : 'mfe-gateway.validations.transaction.transaction-amount-must-be-greater-than-zero'
            ),
            (value) => parseFloat(value.replace(/,/g, '')) > 0
          ),
    }),

    account_number: yup.string().when([], {
      is: () =>
        processMethod === 'manual' && merchantAccount.payment_method !== 'cash',
      then: (schema) =>
        schema
          .required(
            t('mfe-gateway.validations.transaction.account-number-is-required')
          )
          .when([], {
            is: () => merchantAccount.payment_method === 'cc',
            then: (schema) =>
              schema.test(
                'valid-cc-number',
                t('mfe-gateway.validations.transaction.invalid-card-number'),
                (value) => is_valid_luhn(value.replace(/ /g, ''))
              ),
          }),
    }),
    exp_date: yup.string().when([], {
      is: () =>
        merchantAccount.payment_method === 'cc' && processMethod === 'manual',
      then: (schema) =>
        schema
          .required(
            t('mfe-gateway.validations.transaction.expiration-date-is-required')
          )
          .matches(
            /^(0[1-9]|1[0-2])\d{2}$/,
            t('mfe-gateway.validations.transaction.invalid-expiration-date')
          )
          .test(
            'is-expired',
            t('mfe-gateway.validations.transaction.card-is-expired'),
            (value) => {
              if (value.length !== 4) return false
              const month = value.slice(0, 2)
              const year = value.slice(2)
              const currentDate = new Date()
              const currentYear = currentDate.getFullYear().toString().slice(-2)
              const currentMonth = currentDate.getMonth() + 1
              return (
                parseInt(year) > parseInt(currentYear) ||
                (parseInt(year) === parseInt(currentYear) &&
                  parseInt(month) >= currentMonth)
              )
            }
          ),
    }),
    cvv: yup.string().when([], {
      is: () =>
        merchantAccount.payment_method === 'cc' && merchantAccount.vt_cvv,
      then: (schema) =>
        schema
          .when([], {
            is: () =>
              (processMethod === 'manual' &&
                merchantAccount.require_cvv_on_keyed_cnp) ||
              (processMethod === 'wallet' &&
                merchantAccount.require_cvv_on_tokenized_cnp),
            then: (schema) =>
              schema.required(
                t('mfe-gateway.validations.transaction.cvv-is-required')
              ),
          })
          .test(
            'is-valid-cvv',
            t('mfe-gateway.validations.transaction.invalid-cvv'),
            (value, context) => {
              if (!value) return true
              if (processMethod === 'manual') {
                const cardType = getCreditCardType(
                  context.parent.account_number
                )
                if (!cardType) return false
                return cardType.some((type) => type.code.size === value.length)
              } else {
                if (selectedWallet.account_type === 'amex') {
                  return value.length === 4
                }
                return value.length === 3
              }
            }
          ),
    }),

    billing_address: yup.object().shape({
      street: yup.string().when([], {
        is: () => merchantAccount.vt_require_street,
        then: (schema) =>
          schema.required(
            t('mfe-gateway.validations.transaction.street-is-required')
          ),
      }),
      postal_code: yup.string().when([], {
        is: () =>
          merchantAccount.vt_require_zip ||
          !!merchantAccount.surcharge?.stateExceptionCheck,
        then: (schema) =>
          schema.required(
            t('mfe-gateway.validations.transaction.zip-is-required')
          ),
      }),
    }),
    save_account: yup.boolean(),
    save_account_title: yup.string(),
    token_id: yup.string().when([], {
      is: () => processMethod === 'wallet',
      then: (schema) =>
        schema.required(
          t('mfe-gateway.validations.transaction.wallet-is-required')
        ),
    }),
  })

  return schema
}

export default function VirtualTerminal() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { user } = useEnforceLogin()
  const { setNotification } = useNotification()

  const navigate = useNavigate()
  const { selectedLocation } = useLocations()
  const { customerId, walletId } = useParams()

  const [selectedMerchantAccount, setSelectedMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const [selectedTransactionType, setSelectedTransactionType] =
    useState<TransactionType | null>(null)

  const [selectedProcessMethod, setSelectedProcessMethod] =
    useState<ProcessMethod | null>(null)

  const [selectedWallet, setSelectedWallet] = useState<Token | null>(null)
  const [selectedCustomer, setSelectedCustomer] = useState<Contact | null>(null)

  const [processingTransaction, setProcessingTransaction] = useState(false)

  const schema = useMemo(
    () =>
      buildSchema(
        t,
        selectedMerchantAccount,
        selectedProcessMethod,
        selectedWallet,
        selectedTransactionType
      ),
    [
      selectedMerchantAccount,
      selectedProcessMethod,
      selectedWallet,
      t,
      selectedTransactionType,
    ]
  )

  const formMethods = useForm<Partial<TransactionData>>({
    defaultValues: {
      ...calculateInitialVirtualTerminalValues({
        user,
        location: selectedLocation,
        merchantAccount: selectedMerchantAccount,
        processMethod: selectedProcessMethod,
        transactionType: selectedTransactionType,
        accountType:
          selectedMerchantAccount?.payment_method === 'ach' ? 'personal' : null,
      }),
      contact_id: customerId,
      token_id: walletId,
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  // Subscribe to Selected Customer
  useSub<typeof CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT>(
    CUSTOMER_DETAILS_CUSTOMER_CHANGE_EVENT.type,
    ({ data: customer }) => {
      setSelectedCustomer(customer)
    }
  )

  useEffect(() => {
    const country = getDefaultCountry(
      selectedCustomer?.address?.country,
      selectedWallet?.billing_address?.country,
      selectedLocation?.address?.country
    )

    const street =
      selectedWallet?.billing_address?.street ||
      selectedCustomer?.address?.street ||
      ''
    const city =
      selectedWallet?.billing_address?.city ||
      selectedCustomer?.address?.city ||
      ''
    const state =
      selectedWallet?.billing_address?.state ||
      selectedCustomer?.address?.state ||
      ''
    const postal_code =
      selectedWallet?.billing_address?.postal_code ||
      selectedCustomer?.address?.postal_code ||
      ''

    const phone =
      selectedCustomer?.office_phone ||
      selectedWallet?.billing_address?.phone ||
      ''

    formMethods.setValue('billing_address.street', street)
    formMethods.setValue('billing_address.city', city)
    formMethods.setValue('billing_address.state', state)
    formMethods.setValue('billing_address.postal_code', postal_code)
    formMethods.setValue('billing_address.phone', phone)

    formMethods.setValue('billing_address.country', country)
  }, [selectedWallet, formMethods, selectedLocation, selectedCustomer])

  const hasActiveMerchantAccounts = useMemo(() => {
    if (!selectedLocation || Object.keys(selectedLocation).length === 0)
      return undefined

    return getVirtualTerminalMerchantAccounts(selectedLocation).length > 0
  }, [selectedLocation])

  const hasValidPaymentMethod = useMemo(() => {
    if (!selectedLocation) return undefined
    const merchantsAccounts =
      getVirtualTerminalMerchantAccounts(selectedLocation)

    if (selectedWallet && walletId) {
      return merchantsAccounts.some(
        (merchantAccount) =>
          merchantAccount.payment_method === selectedWallet.payment_method
      )
    }
    return true
  }, [selectedWallet, walletId, selectedLocation])

  const isLoading = useMemo(
    () =>
      !selectedLocation ||
      (hasActiveMerchantAccounts &&
        !selectedMerchantAccount &&
        selectedProcessMethod !== 'terminal' &&
        !selectedTransactionType) ||
      hasActiveMerchantAccounts === undefined ||
      hasValidPaymentMethod === undefined,
    [
      selectedLocation,
      hasActiveMerchantAccounts,
      hasValidPaymentMethod,
      selectedMerchantAccount,
      selectedProcessMethod,
      selectedTransactionType,
    ]
  )
  // Subscribe to Selected Merchant Account
  useSub<typeof TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT>(
    TRANSACTION_INFO_MERCHANT_ACCOUNT_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => {
      setSelectedMerchantAccount(merchantAccount)

      formMethods.reset({
        ...formMethods.getValues(),
        ...getDefaultPaymentDetails(
          selectedLocation,
          user,
          merchantAccount,
          selectedProcessMethod,
          merchantAccount?.payment_method === 'ach' ? 'personal' : null
        ),
        billing_address:
          merchantAccount?.payment_method !== 'cash'
            ? formMethods.getValues('billing_address')
            : undefined,
      })
    },
    [selectedProcessMethod]
  )

  // Subscribe to Selected Transaction Type
  useSub<typeof TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT>(
    TRANSACTION_INFO_TRANSACTION_TYPE_CHANGE_EVENT.type,
    ({ data: transactionType }) => {
      setSelectedTransactionType(transactionType)
    }
  )

  // Subscribe to Selected Process Method
  useSub<typeof PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT>(
    PAYMENT_ACCOUNT_DETAILS_PROCESS_METHOD_CHANGE_EVENT.type,
    ({ data: processMethod }) => {
      setSelectedProcessMethod(processMethod)
    },
    [selectedLocation, user, selectedMerchantAccount]
  )

  useSub<typeof CUSTOMER_DETAILS_WALLET_CHANGE_EVENT>(
    CUSTOMER_DETAILS_WALLET_CHANGE_EVENT.type,
    ({ data: token }) => {
      setSelectedWallet(token)
    }
  )

  const onSubmit = async (data: TransactionData) => {
    let payload = preprocessTransactionData({
      ...data,
      location_id: selectedLocation?.id,
    })

    try {
      setProcessingTransaction(true)
      let transactionId = ''

      if (selectedProcessMethod === 'manual') {
        transactionId = await submitKeyedTransaction(
          selectedTransactionType,
          payload
        )
      }

      if (selectedProcessMethod === 'terminal') {
        transactionId = await submitTerminalTransaction(
          selectedTransactionType as TerminalTransactionType,
          payload
        )
      }

      if (selectedProcessMethod === 'wallet') {
        transactionId = await submitTokenTransaction(
          selectedTransactionType as TokenTransactionType,
          payload
        )
      }

      navigate(`confirmation/${transactionId}`)
    } catch (error) {
      // This is handled by the onAPIError callback that can be found further below
      // This is just for the app not to break
    } finally {
      setProcessingTransaction(false)
    }
  }

  useEffect(() => {
    // TODO: This handling of API Errors can be abstracted to be reused.
    const onAPIError = (error: FeathersError) => {
      if (!error.data?.error || typeof error.data?.error === 'undefined') {
        return
      }
      switch (error.data.error.statusCode) {
        case 412:
          error.data.error.meta.details.forEach((errorDetail) => {
            const fieldPath = errorDetail.context.label
            const fieldName = errorDetail.context.key
            const message = errorDetail.message.replace(
              `"${fieldPath}"`,
              apiFieldNameToUpperCase(fieldName)
            )
            formMethods.setError(fieldName, { message })
          })
          break
        case 422:
          const details = error.data.error.meta?.errors || []
          errorConverter(details)

          if (details['billing_address']) {
            const billingAddressErrors = billingAddressErrorConverter({
              errors: details['billing_address'],
            })
            Object.entries(billingAddressErrors).forEach(([key, value]) => {
              formMethods.setError(key as keyof TransactionData, {
                message: apiFieldNameToUpperCase(value.toString()),
              })
            })
          }

          Object.entries(details).forEach(([key, value]) => {
            formMethods.setError(key as keyof TransactionData, {
              message: `${apiFieldNameToUpperCase(
                key
              )} ${value[0].toLowerCase()}`,
            })
          })
          break
        default:
          setNotification({
            label: error.data.error.detail,
            type: 'error',
          })
      }
    }
    api.on('apiError', onAPIError)
    return () => {
      api.off('apiError', onAPIError)
    }
  }, [])

  // TODO: Improve loading logic. We need to hide all VT components during loading, but at the same time we depend on some of them to trigger conditions to show the loading spinner.
  return (
    <>
      {isLoading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress size={50} />
        </Box>
      )}

      <FormProvider {...formMethods}>
        <form
          onSubmit={(event) => {
            // Clear the form errors before submitting,
            // if not the form isn't able to submit
            // https://github.com/react-hook-form/react-hook-form/issues/70#issuecomment-939947190
            formMethods.clearErrors()
            formMethods.handleSubmit(onSubmit)(event)
          }}
        >
          <PageLayoutContainer isButtonBarAtBottom>
            {!hasActiveMerchantAccounts || !hasValidPaymentMethod ? (
              <VirtualTerminalUnavailable
                paymentMethodUnavailable={
                  hasActiveMerchantAccounts && !hasValidPaymentMethod
                }
              />
            ) : (
              <>
                <AppBar
                  sx={{
                    bottom: 0,
                    top: 'auto',
                    position: 'fixed',
                    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                  }}
                >
                  <ButtonBar style={{ marginBottom: '0 !important' }}>
                    <ButtonBarEnd>
                      <Button
                        key={0}
                        containerClassName={classes.submitButton}
                        type="submit"
                        label={t('mfe-gateway.process-transaction')}
                        disabled={processingTransaction}
                        isLoading={processingTransaction}
                        testId="process-transaction-button"
                        guidingId="virtualterminal-process-transaction"
                      />
                    </ButtonBarEnd>
                  </ButtonBar>
                </AppBar>

                <PageLayoutContainerMain>
                  <TransactionInformation />

                  <PageLayoutDivider />

                  <PaymentAccountDetails />
                  {!isLoading && (
                    <>
                      {selectedMerchantAccount?.payment_method !== 'cash' && (
                        <>
                          {!walletId && <PageLayoutDivider />}

                          <BillingInformation
                            showToggle
                            requireStreet={
                              selectedMerchantAccount?.vt_require_street
                            }
                            requireZip={
                              selectedMerchantAccount?.vt_require_zip ||
                              !!selectedMerchantAccount?.surcharge
                                ?.state_exception_check
                            }
                            guidingId="virtualterminal"
                          />

                          <PageLayoutDivider />
                        </>
                      )}
                    </>
                  )}
                  <OptionalInformation />
                </PageLayoutContainerMain>

                <PageLayoutContainerSide>
                  <CustomerDetails
                    disableCustomer={!!customerId}
                    disableWallet={!!walletId}
                    customerRequired={false}
                    hideWallet={
                      !formMethods.getValues('token_id') &&
                      (!formMethods.getValues('contact_id') ||
                        selectedProcessMethod !== 'wallet')
                    }
                    guidingId="virtualterminal"
                  />
                  <TransactionSummary />
                </PageLayoutContainerSide>
              </>
            )}
          </PageLayoutContainer>
        </form>
      </FormProvider>
    </>
  )
}
