import { IServerSideGetRowsParams } from 'ag-grid-enterprise'

import { filterSortBuilder, FixedFilters } from './filterSortBuilder'
import { ServiceTypes, api } from '../../api/src'

export interface FilterParams {
  filterVariant?: 'filterBy' | 'filter'
  fixedFilters?: FixedFilters
}

interface Params {
  [key: string]: string
}

/**
 * DataSource to get data from the api on server side model
 * @param service the service to use from feathers
 * @param filterParams the filter params to use, you can specify what version of filters to use and fixed filters that will be always applied
 * @param extraParams extra params to send as part of the request query
 * @param clientFilter a function to filter the data from the API on the client side
 */
export function DataSource(
  service: keyof Omit<ServiceTypes, 'authentication' | 'users/login'>,
  filterParams: FilterParams = {},
  extraParams: Params = {}, // TODO: This should be removed, does not make sense to have uncontrolled params, but it's needed while API fix is not done on reporting endpoints.
  clientFilter?: (data: unknown[]) => unknown[]
) {
  this.getRows = (params: IServerSideGetRowsParams) => {
    const { sortModel, filterModel = {} } = params.request

    if (params.api.isDestroyed()) {
      return
    }

    const pageSize = params.api.paginationGetPageSize()
    const currentPage = params.api.paginationGetCurrentPage() + 1

    const filterAndSort = filterSortBuilder(
      filterModel,
      sortModel,
      filterParams
    )

    params.api.hideOverlay()

    const originalLocation = window.location.href

    api
      .service(service)
      .find({
        query: {
          ...filterAndSort,
          page: {
            size: pageSize,
            number: currentPage,
          },
          ...extraParams,
        },
        paginate: true,
      })
      .then((result) => {
        const currentLocation = window.location.href
        const data = clientFilter ? clientFilter(result.list) : result.list

        /* The goal of URL comparison is to avoid memory leaks warnings when we left the page before the datasource loads completely,
          destroying the grid and making it impossible for the datasource to set the values */
        if (originalLocation === currentLocation) {
          params.success({
            rowData: data,
            rowCount: result.pagination.total_count,
          })

          if (!data.length) params.api.showNoRowsOverlay()
        }
      })
      .catch(() => {
        params.success({
          rowData: [],
          rowCount: 0,
        })

        params.api.showNoRowsOverlay()
      })
  }
}
