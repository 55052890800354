import { ColDef } from 'ag-grid-community'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import DisplayTable from '@shared/ag-grid/display-table/DisplayTable'
import { LogSMS } from '@shared/api'
import { FieldGroupContainerBlock } from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { formatDatetime, formatPhoneNumber } from '@shared/utils'

interface SMSLogsProps {
  smsLogs: LogSMS[]
  guidingId?: string
}

export const SMSLogs: FC<SMSLogsProps> = ({ smsLogs, guidingId }) => {
  const { t } = useTranslation()

  const { user } = useEnforceLogin()

  const columnDefs: ColDef<LogSMS>[] = useMemo(
    () => [
      {
        headerName: t('common.date'),
        field: 'created_ts',
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz)
        },
        suppressHeaderMenuButton: true,
        sortable: false,
      },
      {
        headerName: t('common.recipient'),
        field: 'recipient',
        valueGetter: (params) => formatPhoneNumber(params.data?.recipient),
        suppressHeaderMenuButton: true,
        sortable: false,
      },
    ],
    []
  )

  return (
    <FieldGroupContainerBlock
      title={t('mfe-gateway.paylink.sms-logs')}
      guidingId={`${guidingId}-smslogs`}
    >
      <DisplayTable
        data={smsLogs}
        columnDefs={columnDefs}
        guidingId={guidingId}
      />
    </FieldGroupContainerBlock>
  )
}
