import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import MultiSelectSearchFilter from '@shared/ag-grid/pagination-table/filters/MultiSelectSearchFilter'
import RadioFilter from '@shared/ag-grid/pagination-table/filters/radio-filter/RadioFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Tags, QuickInvoice, User } from '@shared/api/src/schemas/types'
import {
  ActiveStatus,
  HasPermission,
  PaymentStatus,
  UserNotAllowed,
  TagList,
  ThreeDotMenu,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  useEnforceLogin,
  useFtpPortalHubCommunication,
  useLocations,
  useAuthorization,
  useFilterModel,
} from '@shared/hooks'
import { paymentStatusCodes, EnumServiceName } from '@shared/types'
import {
  formatDate,
  filterAchMerchantAccounts,
  filterCcMerchantAccounts,
  currency,
  checkPermission,
  DataSource,
  formatPhoneNumber,
  formatDatetime,
  sortMerchantAccountsOnGridFilter,
} from '@shared/utils'

import { DeleteQuickInvoice } from '../components/delete-quick-invoice/DeleteQuickInvoice'

const useStyles = tss.withName('QuickInvoiceGrid').create(({ theme }) => ({
  inactive: {
    marginRight: '5px',
  },
}))

export default function QuickInvoiceGrid() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const gridRef = useRef<AgGridReact>(null)
  const { classes } = useStyles(AppTheme)
  const { allMerchantAccounts, locationTags, selectedLocation } = useLocations()
  const { userPermissionSet } = useAuthorization()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const QuickInvoicePrivs = ['v2.quickinvoices.get']

  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectedQuickInvoice, setSelectedQuickInvoice] =
    useState<QuickInvoice>(null)

  const [isDeleteQuickInvoiceOpen, setIsDeleteQuickInvoiceOpen] =
    useState(false)

  const guidingId = 'quickinvoice'

  useEffect(() => {
    setAppBarTitle(t('common.quick-invoice'), null, t('common.gateway'))
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('quick-invoices', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
      },
    })
  }, [selectedLocation?.id])

  const filterPaymentStatusValues = Object.entries(paymentStatusCodes).map(
    ([key, value]) => ({ value: key, label: value })
  )

  const locationOptions = locationTags.map((tag) => tag.title)

  const columnDefs: ColDef<QuickInvoice>[] = useMemo(() => {
    if (allMerchantAccounts?.length > 0) {
      return [
        {
          headerName: t('common.invoice-number'),
          field: 'invoice_number',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          sortable: true,
        },
        {
          headerName: t('common.title'),
          field: 'title',
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
        },
        {
          headerName: t('common.date-due'),
          field: 'due_date',
          floatingFilter: true,
          filter: DateRangeFilter,
          sortable: true,
          sort: 'desc',
          sortIndex: 0,
          valueGetter: (params) => {
            return formatDate(params.data?.due_date, 'UTC') || '-'
          },
          filterParams: {
            type: 'all',
            customFormat: 'yyyy-MM-dd',
          },
        },
        {
          headerName: t('common.date-expiration'),
          field: 'expire_date',
          floatingFilter: true,
          filter: DateRangeFilter,
          sortable: true,
          valueGetter: (params) => {
            return (
              params.data?.expire_date &&
              formatDate(params.data?.expire_date, 'UTC')
            )
          },
          filterParams: {
            type: 'all',
            customFormat: 'yyyy-MM-dd',
          },
        },
        {
          headerName: t('common.name-first'),
          field: 'contact.first_name',
        },
        {
          headerName: t('common.name-last'),
          field: 'contact.last_name',
        },
        {
          headerName: t('common.email'),
          field: 'contact.email',
        },
        {
          headerName: t('common.phone-cell'),
          field: 'contact.cell_phone',
          valueGetter: (params) => {
            return formatPhoneNumber(params.data?.contact?.cell_phone)
          },
        },
        {
          headerName: t('common.amount-due'),
          field: 'amount_due',
          type: 'rightAligned',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            allowedCharPattern: '\\d\\-\\.\\$\\,',
            numberParser: (value: number) => {
              return value ? value * 100 : null
            },
          },
          sortable: true,
          valueGetter: (params) => {
            return currency(params.data?.amount_due / 100)
          },
        },
        {
          headerName: t('common.remaining-balance'),
          field: 'remaining_balance',
          type: 'rightAligned',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            allowedCharPattern: '\\d\\-\\.\\$\\,',
            numberParser: (value: number) => {
              return value ? value * 100 : null
            },
          },
          sortable: true,
          valueGetter: (params) => {
            return currency(params.data?.remaining_balance / 100)
          },
        },
        {
          headerName: t('mfe-gateway.invoice-status'),
          field: 'status_id',
          floatingFilter: true,
          cellRenderer: (data) => {
            return (
              <ActiveStatus
                active={!!data.value}
                customText={{
                  active: t('common.open'),
                  inactive: t('common.closed'),
                }}
              />
            )
          },
          filter: RadioFilter,
          filterParams: {
            defaultTo: 'all',
            ignoreSelectAll: true,
            trueLabel: t('common.open'),
            falseLabel: t('common.closed'),
          },
          sortable: true,
        },
        {
          headerName: t('common.payment-status'),
          field: 'payment_status_id',
          floatingFilter: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: filterPaymentStatusValues.map((option) => option.value),
            valueFormatter: (params) => {
              const option = filterPaymentStatusValues.find(
                (option) => option.value === params.value
              )
              return option ? option.label : params.value
            },
            comparator: (a, b) => {
              const aOption = filterPaymentStatusValues.find(
                (option) => option.value === a
              )
              const bOption = filterPaymentStatusValues.find(
                (option) => option.value === b
              )

              return aOption?.label.localeCompare(bOption?.label)
            },
          },
          cellRenderer: (data) => <PaymentStatus statusCode={data.value} />,
          sortable: true,
        },
        {
          headerName: t('common.active'),
          field: 'active',
          floatingFilter: true,
          cellRenderer: (data) => {
            return <ActiveStatus active={data.value} />
          },
          filter: RadioFilter,
          filterParams: {
            defaultTo: 'true',
            trueLabel: t('common.active'),
            falseLabel: t('common.inactive'),
          },
          sortable: true,
        },
        {
          headerName: t('common.tags'),
          field: 'tags',
          floatingFilter: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: locationOptions,
            comparator: (a: string, b: string) => {
              return a.trim().localeCompare(b.trim())
            },
          },
          cellRenderer: (data) => {
            return (
              <TagList
                items={(data.value as Tags[]) ?? []}
                nameSelector={(tag: Tags) => tag.title}
                wrapWords={false}
              />
            )
          },
        },
        {
          headerName: t('common.merchant-account-cc'),
          field: 'cc_product_transaction_id',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: filterCcMerchantAccounts(allMerchantAccounts).map(
              (option) => option.value
            ),
            comparator: (a: string, b: string) =>
              sortMerchantAccountsOnGridFilter(a, b, allMerchantAccounts),
            valueFormatter: (params) => {
              const option = filterCcMerchantAccounts(allMerchantAccounts).find(
                (option) => option.value === params.value
              )
              return option ? option.label : params.value
            },
          },
          floatingFilter: true,
          valueGetter: (params) => {
            return params.data?.cc_product_transaction?.title
          },
          cellRenderer: (data) => {
            const merchantTitle = data.data?.cc_product_transaction?.title
            const active = data.data?.cc_product_transaction?.active
            return merchantTitle ? (
              <>
                <span className={classes.inactive}>{merchantTitle}</span>

                {!active && <ActiveStatus active={false} />}
              </>
            ) : null
          },
          sortable: true,
        },
        {
          headerName: t('common.merchant-account-ach'),
          field: 'ach_product_transaction_id',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: filterAchMerchantAccounts(allMerchantAccounts).map(
              (option) => option.value
            ),
            comparator: (a: string, b: string) =>
              sortMerchantAccountsOnGridFilter(a, b, allMerchantAccounts),
            valueFormatter: (params) => {
              const option = filterAchMerchantAccounts(
                allMerchantAccounts
              ).find((option) => option.value === params.value)
              return option ? option.label : params.value
            },
          },
          floatingFilter: true,
          valueGetter: (params) => {
            return params.data?.ach_product_transaction?.title
          },
          cellRenderer: (data) => {
            const merchantTitle = data.data?.ach_product_transaction?.title
            const active = data.data?.ach_product_transaction?.active
            return merchantTitle ? (
              <>
                <span className={classes.inactive}>{merchantTitle}</span>

                {!active && <ActiveStatus active={false} />}
              </>
            ) : null
          },
          sortable: true,
        },
        {
          headerName: t('common.date-created'),
          field: 'created_ts',
          floatingFilter: true,
          filter: DateRangeFilter,
          valueGetter: (params) => {
            const timestamp = params.data?.created_ts
            return formatDatetime(timestamp, user?.tz) || '-'
          },
          filterParams: {
            type: 'past',
            showTimePicker: true,
          },
          sortable: true,
        },
        {
          headerName: t('common.created-by'),
          field: 'created_user.id',
          floatingFilter: true,
          filter: MultiSelectSearchFilter,
          filterParams: {
            typeOfSearch: 'contains',
            filterType: 'text',
            placeholder: t('common.email-search'),
            service: 'users',
            filterPropName: 'email',
            primaryDisplayField: 'email',
            value: selectedUsers,
            multiple: true,
            onChange: (event) => {
              setSelectedUsers(event)
            },
          },
          valueGetter: (params) => {
            return params.data?.created_user?.email
          },
        },
      ]
    }
    return []
  }, [allMerchantAccounts, userPermissionSet])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const threeDotsOptions = useCallback(
    (data: QuickInvoice) => [
      {
        label: t('common.view'),
        action: () =>
          navigate(`/merchant/gateway/quick-invoice/${data.id}/view`),
        enabled: true,
        guidingId: `${guidingId}-grid-view`,
      },
      {
        label: t('common.delete'),
        action: () => {
          setSelectedQuickInvoice(data)
          setIsDeleteQuickInvoiceOpen(true)
        },
        enabled:
          !!data.active &&
          checkPermission(userPermissionSet, 'v2.quickinvoices.delete'),
        guidingId: `${guidingId}-grid-delete`,
      },
    ],
    [userPermissionSet]
  )

  const showOrWaitTags = () => {
    if (
      selectedLocation?.tags?.length > 0 &&
      !!locationTags &&
      locationTags.length > 0
    ) {
      return true
    } else if (selectedLocation?.tags?.length === 0) {
      return true
    } else {
      return false
    }
  }

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const defaultFilters = {
    active: {
      filterType: 'set',
      values: ['true', 'false'],
    },
  }

  const paramKeys = [
    {
      queryKey: 'filter[active]',
      filterKey: 'active',
      filterType: 'agSetColumnFilter',
      includeTime: false,
    },
  ]

  const initialFilterModel = useFilterModel(defaultFilters, paramKeys)

  return (
    <section>
      <HasPermission
        allPermissions={QuickInvoicePrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          <DeleteQuickInvoice
            open={isDeleteQuickInvoiceOpen}
            quickInvoice={selectedQuickInvoice}
            onSuccess={refreshGrid}
            onClose={() => {
              setIsDeleteQuickInvoiceOpen(false)
              setSelectedQuickInvoice(null)
            }}
          />

          {allMerchantAccounts.length > 0 && showOrWaitTags() && (
            <PaginationTable<QuickInvoice>
              getRowId={(data) => data.data.id}
              columnDefs={columnDefs}
              rowModelType={'serverSide'}
              serverSideDatasource={datasource}
              defaultColDef={defaultColDef}
              showExportButton={true}
              showClearFiltersButton={true}
              serviceName={EnumServiceName.QuickInvoices}
              onRowClicked={({ data }) =>
                navigate(`/merchant/gateway/quick-invoice/${data.id}/view`)
              }
              getGridRef={getGridRef}
              guidingId={guidingId}
              primaryButtonData={
                checkPermission(userPermissionSet, 'v2.quickinvoices.post')
                  ? {
                      text: t('mfe-gateway.action.quick-invoice.add'),
                      action: () =>
                        navigate('/merchant/gateway/quick-invoice/add'),
                    }
                  : undefined
              }
              initialFilterModel={initialFilterModel}
              threeDotsOptions={threeDotsOptions}
            />
          )}
        </>
      </HasPermission>
    </section>
  )
}
