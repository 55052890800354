import { FC, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { api } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'
import { formatPhoneNumber } from '@shared/utils'

interface ResendPaylinkProps {
  open: boolean
  id: string
  emailOrPhone: string
  onSuccess: () => void
  onClose: () => void
  resendType: 'email' | 'sms'
}

export const ResendPaylink: FC<ResendPaylinkProps> = ({
  open,
  id,
  emailOrPhone,
  onSuccess,
  onClose,
  resendType,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [isLoading, setIsLoading] = useState(false)

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleResend = async () => {
    try {
      if (isMounted.current) {
        setIsLoading(true)
      }
      await api
        .service('paylinks')
        .resend(id, resendType === 'email', resendType === 'sms')

      if (isMounted.current) {
        setNotification({
          type: 'success',
          label:
            resendType === 'email'
              ? t('mfe-gateway.email-resent-successfully')
              : t('mfe-gateway.sms-resent-successfully'),
        })
        onSuccess()
      }
    } catch (error) {
      if (isMounted.current) {
        setNotification({
          type: 'error',
          label: error.message,
        })
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false)
        onClose()
      }
    }
  }

  const formattedEmailOrPhone =
    resendType === 'sms' ? formatPhoneNumber(emailOrPhone) : emailOrPhone

  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.resend-paylink')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
          guidingId: 'paylink-resend-cancel',
          testId: 'cancel-button',
        },
        {
          label: t('mfe-gateway.resend-paylink'),
          onClick: handleResend,
          color: 'primary',
          isLoading: isLoading,
          guidingId: 'paylink-resend-confirm',
          testId: 'resend-button',
        },
      ]}
      onClose={onClose}
      guidingId="paylink-resend-modal"
    >
      <Typography
        sx={{ fontSize: '14px' }}
        variant="body2"
        data-testid="modal-content"
      >
        {t('common.modal.are-you-sure')}{' '}
        <strong>
          {t('mfe-gateway.resend-paylink.resend-this-paylink-to')}
        </strong>{' '}
        <strong>{`${formattedEmailOrPhone}?`}</strong>
      </Typography>
    </ActionModal>
  )
}
