import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { MerchantAccount, Paylinks } from '@shared/api'
import {
  FieldGroupContainerBlock,
  FieldGroupRowStacked,
  FieldGroupViewItemStacked,
} from '@shared/components'
import { paymentStatusPaylinksCodes } from '@shared/types'
import {
  currency,
  formatDatetime,
  formatPhoneNumber,
  useFormatBooleanAsYesNo,
} from '@shared/utils'

interface PaylinkInformationProps {
  paylink: Paylinks
}

export const PaylinkInformation: FC<PaylinkInformationProps> = ({
  paylink,
}) => {
  const { t } = useTranslation()

  const mapDeliveryMethod = (deliveryMethod: number) => {
    switch (deliveryMethod) {
      case 0:
        return t('common.none')
      case 1:
        return t('common.email')
      case 2:
        return t('common.sms')
      case 3:
        return t('mfe-gateway.email-and-sms')
      default:
        return '-'
    }
  }
  const mapTransactionType = (transactionType: string) => {
    switch (transactionType) {
      case 'sale':
        return t('common.sale')
      case 'auth-only':
        return t('common.auth-only')
      default:
        return '-'
    }
  }

  // TODO: Remove casting once the API OAS is updated
  const allMerchantAccounts = [
    (paylink?.cc_product_transaction as MerchantAccount)?.title,
    (paylink?.ach_product_transaction as MerchantAccount)?.title,
  ]
    .filter(Boolean)
    .join('; ')

  const status = paymentStatusPaylinksCodes[paylink?.status_code]
  const createdDate = formatDatetime(paylink?.created_ts)
  const createdBy = (paylink?.created_user as any).email as string
  const transactionType = mapTransactionType(paylink?.paylink_action)
  const deliveryMethod = mapDeliveryMethod(paylink?.delivery_method)
  const amountDue = currency(paylink?.amount_due, true)
  const email = paylink?.email
  const phoneNumber = formatPhoneNumber(paylink?.cell_phone)
  const tokenTitle = paylink?.store_token_title
  const storeToken = useFormatBooleanAsYesNo(paylink?.store_token)
  const description = paylink?.description

  return (
    <FieldGroupContainerBlock
      title={t('mfe-gateway.paylink.paylink-information')}
    >
      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked title={t('common.status')} value={status} />
        <FieldGroupViewItemStacked
          title={t('common.date-created')}
          value={createdDate}
        />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('common.created-by')}
          value={createdBy}
        />
        <FieldGroupViewItemStacked
          title={t('common.transaction-type')}
          value={transactionType}
        />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('common.delivery-method')}
          value={deliveryMethod}
        />
        <FieldGroupViewItemStacked
          title={t('common.merchant-account')}
          value={allMerchantAccounts}
        />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('common.amount-due')}
          value={amountDue}
        />
        <FieldGroupViewItemStacked title={t('common.email')} value={email} />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('common.phone-cell')}
          value={phoneNumber}
        />
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.token-title')}
          value={tokenTitle}
        />
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          title={t('mfe-gateway.paylink.store-token')}
          value={storeToken}
        />
        <FieldGroupViewItemStacked
          title={t('common.description')}
          value={description}
        />
      </FieldGroupRowStacked>
    </FieldGroupContainerBlock>
  )
}
