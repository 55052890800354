import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { PaylinkRequest } from '@shared/api/src'
import {
  FieldGroupContainerBlock,
  FieldGroupRowStacked,
  Input,
  RadioButtons,
  RadioButtonsArray,
  SelectComponent,
} from '@shared/components'
import { useLocations } from '@shared/hooks'

export const AdvancedInformation = () => {
  const { t } = useTranslation()
  const { locationTags } = useLocations()
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext<Partial<PaylinkRequest>>()

  const tagsOptions = useMemo(() => {
    if (!locationTags) return []
    return locationTags.map((tag) => ({
      label: tag.title,
      value: tag.title,
    }))
  }, [locationTags])

  const showBillingFieldsButtons: RadioButtonsArray = [
    {
      title: t('common.yes'),
      onClick: () => setValue('display_billing_fields', true),
      color: 'secondary',
      defaultSelected: true,
      style: {
        width: '100%',
      },
      guidingId: 'paylink-showbillingfields-yes',
    },
    {
      title: t('common.no'),
      onClick: () => setValue('display_billing_fields', false),
      color: 'secondary',
      defaultSelected: false,
      style: {
        width: '100%',
      },
      guidingId: 'paylink-showbillingfields-no',
    },
  ]

  return (
    <FieldGroupContainerBlock
      showContent
      showToggle
      title={t('mfe-gateway.paylink.advanced-information')}
      guidingId="paylink-advanced-information"
    >
      {tagsOptions.length > 0 && (
        <FieldGroupRowStacked>
          <Grid item xs={12}>
            <Controller
              name="tags"
              control={control}
              render={({ field }) => (
                <SelectComponent
                  multiple
                  onChange={field.onChange}
                  value={field.value}
                  options={tagsOptions}
                  label={t('common.tags')}
                  placeholder={t('common.tags-select')}
                  style={{
                    width: '100%',
                    maxWidth: 'unset',
                    border: 'unset',
                    height: '44px',
                  }}
                  error={!!errors.tags}
                  helperText={errors.tags?.message.toString()}
                  guidingId="paylink-tags"
                />
              )}
            />
          </Grid>
        </FieldGroupRowStacked>
      )}

      <FieldGroupRowStacked>
        <Grid item xs={6}>
          <RadioButtons
            label={t('mfe-gateway.paylink.show-billing-fields')}
            buttons={showBillingFieldsButtons}
            guidingId="paylink-showbillingfields"
          />
        </Grid>
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="paylink_api_id"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('mfe-gateway.paylink.api-id')}
                placeholder={t('mfe-gateway.paylink.api-id-placeholder')}
                error={!!errors.paylink_api_id}
                helperText={
                  !!errors.paylink_api_id
                    ? errors.paylink_api_id?.message?.toString()
                    : t('common.used-for-software-integrations-only')
                }
                guidingId="paylink-apiid"
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="redirect_url_on_approve"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('mfe-gateway.paylink.approval-direct-url')}
                placeholder={t(
                  'mfe-gateway.paylink.approval-direct-url-placeholder'
                )}
                error={!!errors.redirect_url_on_approve}
                helperText={errors.redirect_url_on_approve?.message.toString()}
                guidingId="paylink-approvalurl"
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="redirect_url_on_decline"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('mfe-gateway.paylink.decline-direct-url')}
                placeholder={t(
                  'mfe-gateway.paylink.decline-direct-url-placeholder'
                )}
                error={!!errors.redirect_url_on_approve}
                helperText={errors.redirect_url_on_approve?.message.toString()}
                guidingId="paylink-declineurl"
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="redirect_url_delay"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('mfe-gateway.paylink.redirect-delay')}
                placeholder={t(
                  'mfe-gateway.paylink.redirect-delay-placeholder'
                )}
                error={!!errors.redirect_url_on_approve}
                helperText={errors.redirect_url_on_approve?.message.toString()}
                type="number"
                guidingId="paylink-redirectdelay"
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>
    </FieldGroupContainerBlock>
  )
}
