import React from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { getCountryByIdAlpha2 } from '@shared/utils'
export interface AddressProps {
  customer: Contact
}

const Address: React.FC<AddressProps> = ({ customer }) => {
  const { t } = useTranslation()

  const valueAddressCity = customer?.address.city
  const ValueAddressStreet = customer?.address.street
  const valueAddressState = customer?.address.state
  const valueAddressCountry = customer?.address.country
    ? getCountryByIdAlpha2(customer.address.country)
    : ''
  const valueAddressPostalCode = customer?.address.postal_code

  return (
    <FieldGroupContainer title={t('common.address')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.street')}
          value={ValueAddressStreet}
          valueTestID="address-street-value"
        />
        <FieldGroupViewItem
          title={t('common.city')}
          value={valueAddressCity}
          valueTestID="address-city-value"
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.state')}
          value={valueAddressState}
          valueTestID="address-state-value"
        />
        <FieldGroupViewItem
          title={t('common.country')}
          value={valueAddressCountry}
          valueTestID="address-country-value"
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.zip-code')}
          value={valueAddressPostalCode}
          valueTestID="address-zip-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default Address
