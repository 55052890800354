import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Paylinks } from '@shared/api'
import {
  FieldGroupRowStacked,
  FieldGroupViewItemStacked,
} from '@shared/components'
import { currency, formatDate } from '@shared/utils'

const useStyles = tss.withName('PaylinkSummary').create(({ theme }) => ({
  root: {
    height: 'fit-content',
    width: '100%',
    padding: '1em',
    backgroundColor: 'white',
    borderRadius: '.5em',
    marginTop: '24px',
  },
  title: {
    marginBottom: '24px',
    color: theme.palette['neutral-900'],
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  fieldContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1em 0',
  },
  fieldLabel: {
    color: theme.palette['neutral-600'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  totalAmount: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
    color: theme.palette['neutral-900'],
  },
}))

interface PaylinkSummaryProps {
  paylink: Paylinks
}

export const PaylinkSummary: FC<PaylinkSummaryProps> = ({ paylink }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {t('mfe-gateway.paylink.paylink-summary')}
      </Typography>

      <FieldGroupRowStacked>
        <FieldGroupViewItemStacked
          isFullWidth
          isValueAlignRight
          title={t('common.date-expiration')}
          value={formatDate(paylink.expire_date)}
          direction="row"
        />
      </FieldGroupRowStacked>

      <Box className={classes.fieldContainer}>
        <Typography variant="body2" className={classes.fieldLabel}>
          {t('common.total-amount')}
        </Typography>

        <Typography
          variant="body2"
          className={classes.totalAmount}
          data-testid="total-amount-value"
        >
          {currency(paylink.amount_due, true)}
        </Typography>
      </Box>
    </Box>
  )
}
