import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'

import { Box, TextField, Typography } from '@mui/material'

import { phoneMask } from '@shared/utils'

import { useInputStyles } from './Input'

export interface InputProps {
  label: string
  value?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  error?: boolean
  helperText?: string
  required?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  testId?: string
  inputRef?: React.Ref<any>
  autoFocus?: boolean
  guidingId?: string
  isRightCombined?: boolean
}

export const Input = forwardRef<unknown, InputProps>(
  (
    {
      label,
      value,
      onChange,
      onBlur,
      error = false,
      helperText,
      required = false,
      disabled = false,
      style,
      testId,
      autoFocus,
      guidingId,
      isRightCombined,
    },
    ref
  ) => {
    const { classes } = useInputStyles()

    return (
      <Box sx={style} data-guiding-id={`${guidingId}-container`}>
        <Box sx={{ display: 'flex', marginBottom: '.6em' }}>
          <Typography className={classes.label}>{label}</Typography>
          {required && (
            <Typography
              className={classes.required}
              data-testid={`${testId}-required`}
            >
              *
            </Typography>
          )}
        </Box>
        <TextField
          inputRef={ref}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          disabled={disabled}
          className={classes.input}
          fullWidth
          size="small"
          inputProps={{
            'data-testid': testId,
            'data-guiding-id': guidingId,
            style: { height: '44px' },
          }}
          InputProps={{
            inputComponent: PhoneMaskInput,
            style: isRightCombined && {
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            },
          }}
          FormHelperTextProps={{
            id: `${testId}-helper`,
            className: `${classes.helperText} ${
              error ? classes.helperTextError : ''
            }`,
          }}
          autoFocus={autoFocus}
        ></TextField>
      </Box>
    )
  }
)

const PhoneMaskInput = React.forwardRef((props, _) => {
  const { ...other } = props
  return (
    <MaskedInput {...other} mask={phoneMask} placeholder="(000) 000-0000" />
  )
})
