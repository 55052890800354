import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, Box } from '@mui/material'

import { FieldGroupContainer, Input, InputPhone } from '@shared/components'

interface FormData {
  email: string
  home_phone: string
  cell_phone: string
  home_phone_country_code: string
  cell_phone_country_code: string
}

const useStyles = tss.withName('ContactInformation').create(({ theme }) => ({
  country: {
    marginRight: '-8px',
  },
}))

const ContactInformation = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<FormData>()

  return (
    <FieldGroupContainer title={t('mfe-gateway.contact-information')}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item sm={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="email-input"
                label={t('common.email')}
                placeholder={t('common.email-placeholder')}
                error={!!errors.email}
                helperText={errors.email?.message}
                guidingId={'customers-email'}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} md={2}>
          <Box className={classes.country}>
            <Controller
              name="home_phone_country_code"
              control={control}
              render={({ field }) => (
                <Input
                  startAdornmentText="+"
                  placeholder="1"
                  isLeftCombined={true}
                  {...field}
                  testId="home-phone-country-code-input"
                  error={!!errors.home_phone_country_code}
                  helperText={errors.home_phone_country_code?.message}
                  guidingId={'customers-home-phone-country-code'}
                  label={t('common.phone-home')}
                  onChange={(event) => {
                    let str = event.target.value
                    if (str?.length > 5) {
                      str = str.substring(0, str.length - 1)
                    }
                    field.onChange(str.replace(/\D/g, ''))
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={6} md={4}>
          <Controller
            name="home_phone"
            control={control}
            render={({ field }) => (
              <InputPhone
                isRightCombined={true}
                style={{ marginTop: '20px' }}
                {...field}
                testId="home-phone-input"
                label={' '}
                error={!!errors.home_phone}
                helperText={errors.home_phone?.message}
                guidingId={'customers-homephone'}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} md={2}>
          <Box className={classes.country}>
            <Controller
              name="cell_phone_country_code"
              control={control}
              render={({ field }) => (
                <Input
                  startAdornmentText="+"
                  placeholder="1"
                  isLeftCombined={true}
                  {...field}
                  testId="cell-phone-country-code-input"
                  label={t('common.phone-cell')}
                  error={!!errors.cell_phone_country_code}
                  helperText={errors.cell_phone_country_code?.message}
                  guidingId={'customers-cell-phone-country-code'}
                  onChange={(event) => {
                    let str = event.target.value
                    if (str?.length > 5) {
                      str = str.substring(0, str.length - 1)
                    }
                    field.onChange(str.replace(/\D/g, ''))
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={4}>
          <Controller
            name="cell_phone"
            control={control}
            render={({ field }) => (
              <InputPhone
                isRightCombined={true}
                style={{ marginTop: '20px' }}
                {...field}
                testId="cell-phone-input"
                label={' '}
                error={!!errors.cell_phone}
                helperText={errors.cell_phone?.message}
                guidingId={'customers-cellphone'}
              />
            )}
          />
        </Grid>
      </Grid>
    </FieldGroupContainer>
  )
}

export default ContactInformation
