import { ColDef } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DisplayTable from '@shared/ag-grid/display-table/DisplayTable'
import { Transaction } from '@shared/api'
import {
  TransactionStatus,
  TransactionDetailsModal,
  FieldGroupContainerBlock,
} from '@shared/components'
import { useEnforceLogin } from '@shared/hooks'
import { currency, formatDatetime } from '@shared/utils'

export type PaymentHistoryProps = {
  canViewTransactions: boolean
  paymentData: Transaction[]
  onRefresh?: () => void
  guidingId?: string
}

function PaymentHistory({
  canViewTransactions,
  paymentData,
  onRefresh,
  guidingId,
}: PaymentHistoryProps) {
  const { t } = useTranslation()

  const { user } = useEnforceLogin()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [transactionId, setTransactionId] = useState<string>()

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setTransactionId(undefined)
    setIsModalOpen(false)
  }

  const columnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        headerName: t('common.date-payment'),
        field: 'created_ts',
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz) !== '-'
            ? formatDatetime(timestamp, user?.tz)
            : ''
        },
        suppressHeaderMenuButton: true,
        sortable: false,
      },
      {
        headerName: t('common.amount'),
        field: 'transaction_amount',
        type: 'rightAligned',
        cellRenderer: (params) => {
          if (isNaN(params.data?.transaction_amount)) {
            return <b>{params.data?.transaction_amount}</b>
          } else {
            return currency(params.data?.transaction_amount / 100)
          }
        },
        suppressHeaderMenuButton: true,
        sortable: false,
      },
      {
        headerName: t('common.status'),
        field: 'status_code',
        cellRenderer: (data) => <TransactionStatus statusCode={data.value} />,
        suppressHeaderMenuButton: true,
        sortable: false,
      },
    ],
    [t, user]
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const handleRowClick = useCallback(
    async (event) => {
      if (canViewTransactions) {
        setTransactionId(event.data.id)
        openModal()
      }
    },
    [canViewTransactions]
  )

  return (
    <>
      <FieldGroupContainerBlock
        title={t('common.payment-history')}
        guidingId={`${guidingId}-paymenthistory`}
      >
        <DisplayTable<Transaction>
          columnDefs={columnDefs}
          data={paymentData}
          domLayout="autoHeight"
          onRowClicked={handleRowClick}
          defaultColDef={defaultColDef}
          guidingId={guidingId}
        />
      </FieldGroupContainerBlock>

      {transactionId ? (
        <TransactionDetailsModal
          isModalOpen={isModalOpen}
          onClose={closeModal}
          transactionId={transactionId}
          tz={user.tz}
          onTransactionChange={() => {
            onRefresh?.()
            closeModal()
          }}
        />
      ) : null}
    </>
  )
}
export default PaymentHistory
