import { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid } from '@mui/material'

import { MerchantAccount } from '@shared/api/src'
import {
  FieldGroupContainer,
  SelectComponent,
  SelectOption,
} from '@shared/components'
import { useLocations } from '@shared/hooks'
import { PubSubEvent, usePub } from '@shared/hooks/usePub'
import { PaymentMethodType } from '@shared/types'
import { sortMerchantAccounts, getDefaultMerchantAccount } from '@shared/utils'

export const RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT =
  new Event(
    'RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE'
  ) as PubSubEvent<MerchantAccount | null>

const useStyles = tss
  .withName('MerchantAccountDetails')
  .create(({ theme }) => ({
    root: {},
  }))

interface FormData {
  product_transaction_id: string
}

const MerchantAccountDetails = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<FormData>()
  const { classes } = useStyles()
  const { t } = useTranslation()
  const publish = usePub()

  const { allMerchantAccounts, selectedLocation } = useLocations()
  const [merchantAccounts, setMerchantAccounts] = useState<MerchantAccount[]>(
    []
  )

  const [defaultMerchantAccount, setDefaultMerchantAccount] =
    useState<MerchantAccount>()

  useEffect(() => {
    if (!allMerchantAccounts) return

    const merchantAccounts = allMerchantAccounts.filter(
      ({ payment_method, active, industry_type }) =>
        payment_method !== PaymentMethodType.CASH &&
        active &&
        industry_type !== 'lodging'
    )

    setDefaultMerchantAccount(getDefaultMerchantAccount(selectedLocation))

    setMerchantAccounts(merchantAccounts)
  }, [allMerchantAccounts])

  useEffect(() => {
    if (!merchantAccounts?.length) {
      return
    }
    const merchantAccountId = getValues('product_transaction_id')
    let includesId = false
    merchantAccounts.forEach((account) => {
      if (account.id === merchantAccountId) {
        includesId = true
      }
    })

    let firstAccountId
    if (!merchantAccountId && !defaultMerchantAccount) {
      if (merchantAccounts[0]?.id) {
        firstAccountId = merchantAccounts[0]?.id
      } else {
        return
      }
    }

    const selectedMerchantAccountId = includesId
      ? merchantAccountId || defaultMerchantAccount?.id || firstAccountId
      : defaultMerchantAccount?.id || firstAccountId

    setValue('product_transaction_id', selectedMerchantAccountId)
    publish(
      RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT,
      merchantAccounts.find(
        (merchantAccount) => merchantAccount.id === selectedMerchantAccountId
      )
    )
  }, [defaultMerchantAccount, getValues, merchantAccounts, setValue, publish])

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(merchantAccounts, true, true, true).map<
      SelectOption<MerchantAccount>
    >((merchantAccount) => ({
      label: merchantAccount.title,
      value: merchantAccount.id,
      payment_method: merchantAccount.payment_method,
    }))

  const merchantAccountsOptions =
    getMerchantAccountsSelectOptions(merchantAccounts)

  return (
    <>
      {merchantAccountsOptions.length > 1 ? (
        <FieldGroupContainer>
          <Grid container spacing={1} rowSpacing={2}>
            <Grid item sm={12} md={12}>
              <Controller
                name="product_transaction_id"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    {...field}
                    required
                    label={t('common.merchant-account-placeholder')}
                    placeholder={t('common.merchant-account-placeholder')}
                    options={merchantAccountsOptions}
                    value={field.value}
                    onChange={(event) => {
                      publish(
                        RECURRING_PAYMENT_ACCOUNT_DETAILS_MERCHANT_ACCOUNT_CHANGE_EVENT,
                        merchantAccounts.find(
                          (merchantAccount) =>
                            merchantAccount.id === event.target.value
                        )
                      )
                      field.onChange(event)
                    }}
                    multiple={false}
                    sort={false}
                    style={{
                      minWidth: '100%',
                      width: '100%',
                      marginTop: '4px',
                      marginBottom: '6px',
                    }}
                    error={!!errors.product_transaction_id}
                    helperText={errors.product_transaction_id?.message}
                    testId="recurring-billing-merchant-account-select"
                    guidingId="recurringbilling-merchantaccount"
                  />
                )}
              />
            </Grid>
          </Grid>
        </FieldGroupContainer>
      ) : null}
    </>
  )
}
export default MerchantAccountDetails
