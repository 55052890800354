import {
  TransactionData,
  Location,
  MerchantAccount,
  TransactionType,
  User,
} from '../../../api/src'
import { AccountType, ProcessMethod } from '../../../types'
import { getDefaultPaymentDetails } from '../get-default-payment-details/getDefaultPaymentDetails'
import { getDefaultTransactionAmounts } from '../get-default-transaction-amounts/getDefaultTransactionAmounts'

type InputData = {
  location: Location
  user: User
  merchantAccount: MerchantAccount | null
  processMethod: ProcessMethod
  transactionType: TransactionType
  accountType: AccountType
  formValues?: Partial<TransactionData>
}

//Calculates default values for the form based on the selected location and merchantAccount.
export const calculateInitialVirtualTerminalValues = ({
  location,
  user,
  merchantAccount,
  processMethod,
  transactionType,
  accountType,
  formValues,
}: InputData): TransactionData => {
  const mapCountries = [
    { old: 'US', new: 'USA' },
    { old: 'CA', new: 'CAN' },
  ]

  const mapCountryCode = (country?: string) => {
    if (!country) return ''

    const found = mapCountries.find((c) => c.old === country)
    if (found) {
      return found.new
    }
    return country
  }
  return {
    location_id: location.id,
    product_transaction_id: merchantAccount?.id ?? '',
    account_number: '',
    auth_code: '',
    exp_date: '',
    cvv: '',
    description: formValues?.description ? formValues?.description : '',
    tags: formValues?.tags ? formValues?.tags : [],
    clerk_number: formValues?.clerk_number ? formValues?.clerk_number : '',
    order_number: formValues?.order_number ? formValues?.order_number : '',

    transaction_c1: formValues?.transaction_c1
      ? formValues?.transaction_c1
      : '',
    transaction_c2: formValues?.transaction_c2
      ? formValues?.transaction_c2
      : '',
    transaction_c3: formValues?.transaction_c3
      ? formValues?.transaction_c3
      : '',
    billing_address: {
      city: formValues?.billing_address?.city
        ? formValues?.billing_address?.city
        : '',
      state: formValues?.billing_address?.state
        ? formValues?.billing_address?.state
        : '',
      country: mapCountryCode(location.address?.country ?? ''),
      street: formValues?.billing_address?.street
        ? formValues?.billing_address?.street
        : '',
      postal_code: formValues?.billing_address?.postal_code
        ? formValues?.billing_address?.postal_code
        : '',
      phone: formValues?.billing_address?.phone
        ? formValues?.billing_address?.phone
        : '',
    },
    ...getDefaultTransactionAmounts(merchantAccount, transactionType),
    ...getDefaultPaymentDetails(
      location,
      user,
      merchantAccount,
      processMethod,
      accountType ?? null
    ),
  } as TransactionData
}
