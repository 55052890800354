import {
  MerchantAccount,
  Surcharge,
  TransactionType,
  User,
} from '../../../api/src'
import { valueToNumber } from '../../../api/src/utils/valueToNumber'
import { calculateTax } from '../calculate-tax/calculateTax'

interface ReturnType {
  tax?: number
  transaction_amount: number
}

const calculateTaxForTotals = (
  merchantAccount: MerchantAccount,
  subtotal: number,
  taxRate: number | undefined
) => {
  if (!merchantAccount.vt_enable_sales_tax || taxRate === undefined) {
    return
  }

  return calculateTax(subtotal, taxRate)
}

type TransactionTotalsData = {
  merchantAccount: MerchantAccount
  subtotal: number | string //May be stored as a string, accepting strings simplifies this.
  taxRate: number | undefined
  tip: number | undefined
  taxOverride: number | undefined
}

/**
 * Calculates totals for the virtual terminal.  The structure returned is designed to be spread over a transaction form.
 */
export const calculateTransactionTotals = ({
  merchantAccount,
  subtotal,
  taxRate,
  taxOverride,
  tip,
}: TransactionTotalsData): ReturnType => {
  //Start by handling string subtotals.  If it's a string and isn't valid, just return a transaction amount of 0.
  if (typeof subtotal === 'string') {
    subtotal = valueToNumber(subtotal) //Remove commas from large numbers (ie. 1,000,000)
  }

  if (typeof tip === 'string') {
    tip = valueToNumber(tip)
  }

  if (isNaN(subtotal)) {
    return { transaction_amount: 0 }
  }

  let transaction_amount = Math.round(subtotal * 100) / 100

  let tax: number | undefined = undefined
  if (merchantAccount.payment_method !== 'cash') {
    tax =
      taxOverride ??
      calculateTaxForTotals(merchantAccount, transaction_amount, taxRate)
  }

  transaction_amount += tax ? Number(tax) : 0
  transaction_amount += tip ? Number(tip) : 0

  return {
    transaction_amount,
    tax,
  }
}
