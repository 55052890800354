import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import {
  FieldGroupRow,
  SelectComponent,
  SelectOption,
} from '@shared/components'
import { PubSubEvent, useLocations, usePub } from '@shared/hooks'
import { PaymentMethodType } from '@shared/types'
import { sortMerchantAccounts } from '@shared/utils'

export const MERCHANT_ACCOUNT_CC_CHANGE_EVENT = new Event(
  'MERCHANT_ACCOUNT_CC_CHANGE'
) as PubSubEvent<MerchantAccount | null>

export const MERCHANT_ACCOUNT_ACH_CHANGE_EVENT = new Event(
  'MERCHANT_ACCOUNT_ACH_CHANGE'
) as PubSubEvent<MerchantAccount | null>

interface MerchantSelectorProps {
  isEditable?: boolean
  defaultMerchants?: boolean
  variant?: 'quickinvoice' | 'paylink'

  ccSelectorProps?: {
    helperText?: string
    disabled?: boolean
  }
  achSelectorProps?: {
    helperText?: string
    disabled?: boolean
  }
}

type variantFormData = {
  ach_product_transaction_id?: string
  cc_product_transaction_id?: string
}

export const MerchantSelector: FC<MerchantSelectorProps> = ({
  isEditable = true,
  defaultMerchants = false,
  variant = 'quickinvoice',
  ccSelectorProps,
  achSelectorProps,
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext<variantFormData>()

  const publish = usePub()

  const { t } = useTranslation()

  const { allMerchantAccounts, selectedLocation } = useLocations()
  const [CCMerchantAccounts, setCCMerchantAccounts] = useState<
    MerchantAccount[]
  >([])
  const [ACHMerchantAccounts, setACHMerchantAccounts] = useState<
    MerchantAccount[]
  >([])

  const [isComponentHidden, setIsComponentHidden] = useState<boolean>(false)

  useEffect(() => {
    if (!allMerchantAccounts) return

    let merchantAccounts = allMerchantAccounts.filter(
      ({ payment_method, active }) =>
        payment_method !== PaymentMethodType.CASH && active
    )

    if (variant === 'quickinvoice') {
      merchantAccounts = merchantAccounts.filter(
        ({ quick_invoice_allow }) => !!quick_invoice_allow
      )
    } else {
      merchantAccounts = merchantAccounts.filter(
        ({ paylink_allow }) => !!paylink_allow
      )
    }

    const ccMerchants = (
      isEditable ? merchantAccounts : allMerchantAccounts
    )?.filter(({ payment_method }) => payment_method === PaymentMethodType.CC)

    const achMerchants = (
      isEditable ? merchantAccounts : allMerchantAccounts
    )?.filter(({ payment_method }) => payment_method === PaymentMethodType.ACH)

    // Only set default merchant accounts if creating a new record
    if (defaultMerchants) {
      if (ccMerchants.length > 0) {
        const ccDefault =
          ccMerchants.find(
            (merchant) => merchant.id === selectedLocation.default_cc
          ) || ccMerchants[0]

        if (ccDefault) {
          setValue('cc_product_transaction_id', ccDefault.id)
          publish(MERCHANT_ACCOUNT_CC_CHANGE_EVENT, ccDefault)
        } else {
          setValue('cc_product_transaction_id', '')
          publish(MERCHANT_ACCOUNT_CC_CHANGE_EVENT, null)
        }
      }

      if (achMerchants.length > 0) {
        const achDefault =
          achMerchants.find(
            (merchant) => merchant.id === selectedLocation.default_ach
          ) || achMerchants[0]

        if (achDefault) {
          setValue('ach_product_transaction_id', achDefault.id)
          publish(MERCHANT_ACCOUNT_ACH_CHANGE_EVENT, achDefault)
        } else {
          setValue('ach_product_transaction_id', '')
          publish(MERCHANT_ACCOUNT_ACH_CHANGE_EVENT, null)
        }
      }
    } else {
      const currentCC = getValues('cc_product_transaction_id')
      const currentACH = getValues('ach_product_transaction_id')

      let allow =
        variant === 'quickinvoice' ? 'quick_invoice_allow' : 'paylink_allow'

      if (currentCC) {
        const ccMerchant = allMerchantAccounts.find(
          (merchantAccount) => merchantAccount.id === currentCC
        )
        setValue(
          'cc_product_transaction_id',
          ccMerchant[allow] ? currentCC : ''
        )
        publish(
          MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
          ccMerchant[allow] ? ccMerchant : null
        )
      }

      if (currentACH) {
        const achMerchant = allMerchantAccounts.find(
          (merchantAccount) => merchantAccount.id === currentACH
        )
        setValue(
          'ach_product_transaction_id',
          achMerchant[allow] ? currentACH : ''
        )
        publish(
          MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
          achMerchant[allow] ? achMerchant : null
        )
      }
    }

    if (
      (ccMerchants.length === 1 && achMerchants.length === 0) ||
      (ccMerchants.length === 0 && achMerchants.length === 1)
    ) {
      setIsComponentHidden(true)
    }

    setCCMerchantAccounts(ccMerchants)
    setACHMerchantAccounts(achMerchants)
  }, [allMerchantAccounts])

  const getMerchantAccountsSelectOptions = (
    merchantAccounts: MerchantAccount[]
  ): SelectOption<MerchantAccount>[] =>
    sortMerchantAccounts(merchantAccounts).map<SelectOption<MerchantAccount>>(
      (merchantAccount) => ({
        label: merchantAccount.title,
        value: merchantAccount.id,
      })
    )

  const noneOption = {
    label: t('common.none'),
    value: '',
  }

  const ccMerchantAccountsOptions =
    getMerchantAccountsSelectOptions(CCMerchantAccounts)

  const achMerchantAccountsOptions =
    getMerchantAccountsSelectOptions(ACHMerchantAccounts)

  if (isComponentHidden) return null

  return (
    <FieldGroupRow columnSpacing="24px">
      {ccMerchantAccountsOptions.length > 0 && (
        <Grid item xs={achMerchantAccountsOptions.length > 0 ? 6 : 12}>
          <Controller
            name="cc_product_transaction_id"
            control={control}
            render={({ field }) => (
              <SelectComponent
                {...field}
                label={t('mfe-gateway.select-cc-merchant-account')}
                placeholder={t(
                  'mfe-gateway.select-cc-merchant-account-placeholder'
                )}
                testId="cc-merchant-account-select"
                options={
                  achMerchantAccountsOptions.length > 0 &&
                  !achSelectorProps?.disabled
                    ? [noneOption, ...ccMerchantAccountsOptions]
                    : ccMerchantAccountsOptions
                }
                value={field.value}
                onChange={(event) => {
                  publish(
                    MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
                    event.target.value
                      ? allMerchantAccounts.find(
                          (merchantAccount) =>
                            merchantAccount.id === event.target.value
                        )
                      : null
                  )
                  field.onChange(event)
                }}
                style={{
                  minWidth: '100%',
                  width: '100%',
                  height: '44px',
                }}
                error={!!errors.cc_product_transaction_id}
                helperText={
                  errors.cc_product_transaction_id?.message.toString() ||
                  ccSelectorProps?.helperText
                }
                disabled={!isEditable || ccSelectorProps?.disabled}
                guidingId={`${variant}-merchantselector-cc`}
              />
            )}
          />
        </Grid>
      )}

      {achMerchantAccountsOptions.length > 0 && (
        <Grid item xs={ccMerchantAccountsOptions.length > 0 ? 6 : 12}>
          <Controller
            name="ach_product_transaction_id"
            control={control}
            render={({ field }) => (
              <SelectComponent
                {...field}
                label={t('mfe-gateway.select-ach-merchant-account')}
                placeholder={t(
                  'mfe-gateway.select-ach-merchant-account-placeholder'
                )}
                testId="ach-merchant-account-select"
                options={
                  ccMerchantAccountsOptions.length > 0 &&
                  !ccSelectorProps?.disabled
                    ? [noneOption, ...achMerchantAccountsOptions]
                    : achMerchantAccountsOptions
                }
                value={field.value}
                onChange={(event) => {
                  publish(
                    MERCHANT_ACCOUNT_ACH_CHANGE_EVENT,
                    event.target.value
                      ? allMerchantAccounts.find(
                          (merchantAccount) =>
                            merchantAccount.id === event.target.value
                        )
                      : null
                  )
                  field.onChange(event)
                }}
                style={{
                  minWidth: '100%',
                  width: '100%',
                  height: '44px',
                }}
                error={!!errors.ach_product_transaction_id}
                helperText={
                  errors.ach_product_transaction_id?.message.toString() ||
                  achSelectorProps?.helperText
                }
                disabled={!isEditable || achSelectorProps?.disabled}
                guidingId={`${variant}-merchantselector-ach`}
              />
            )}
          />
        </Grid>
      )}
    </FieldGroupRow>
  )
}
