import { get } from 'lodash'

import { FeathersError } from '../../api/src'
import { apiFieldNameToUpperCase } from '../api-field-name-to-upper-case/apiFieldNameToUpperCase'
import errorMapping from '../error-mapping/errorMapping'
// TODO: consider this enum to be reused globally
export enum HttpStatus {
  'UNPROCESSABLE_ENTITY' = 422,
}

type FieldError = {
  message: string
  path: string[]
}

type SecondTypeFieldErrors = Record<string, string>

export type FieldErrorsResponse = {
  type: string
  id: string
  data?: {
    error?: {
      meta?: {
        name?: string
        details?: FieldError[]
        errors?: SecondTypeFieldErrors
        message?: string
      }
      statusCode: number
    }
  }
}

export const toFieldErrors = (error: FieldErrorsResponse): any => {
  const statusCode = error?.data?.error?.statusCode

  const details =
    error?.data?.error?.meta?.details || error?.data?.error?.meta?.errors || []

  // Unprocessable Entity errors
  if (statusCode === HttpStatus.UNPROCESSABLE_ENTITY) {
    errorConverter(details as SecondTypeFieldErrors)
    return details
  }

  return (<FieldError[]>details).reduce(
    (acc: NestedObject, error: FieldError) => {
      if (error?.path) {
        //Nested errors because ErrorMessage was not displaying the error otherwise
        if (error.path.length > 1) {
          const [parent, child] = error.path
          acc[parent] = acc[parent] || {}
          ;(acc[parent] as NestedObject)[child] = error.message
        } else {
          acc[error.path.join('.')] = error.message
        }
      }
      return acc
    },
    {}
  )
}

type BillingAddressMapping = {
  basePath?: string
  errors: {
    [key: string]: string
  }
}

export const billingAddressErrorConverter = ({
  basePath = 'billing_address',
  errors,
}: BillingAddressMapping) => {
  const keys = ['street', 'city', 'state', 'postal_code', 'country', 'phone']
  const result: NestedObject = {}
  keys.forEach((key) => {
    const fullKey = `"${basePath}.${key}"`
    const label = fullKey
      .replace(/"/g, '')
      .replace(/_/g, ' ')
      .replace(/\./g, ' ')

    let value = get(errors, key)
    if (value) {
      if (Array.isArray(value)) {
        value = value[0]
      }
      result[key] = value.replace(fullKey, label)
    }
  })

  return result
}

type NestedObject = { [key: string]: string | NestedObject }

export const errorConverter = (errors: NestedObject) => {
  errorMapping.forEach((error) => {
    if (errors[error.legacy]) {
      if (error.oneco.includes('.')) {
        const [parent, child] = error.oneco.split('.')
        errors[parent] = errors[parent] || {}
        ;(errors[parent] as NestedObject)[child] = errors[error.legacy]
      } else {
        errors[error.oneco] = errors[error.legacy]
      }
      delete errors[error.legacy]
    }
  })
}

export const toArrayFieldErrors = (
  error: FieldErrorsResponse
): {
  field: any
  message: string
  context?: { key: string; label: string; value: string | number }
}[] => {
  const statusCode = error.data?.error?.statusCode

  switch (statusCode) {
    case 412:
      if (!error?.data?.error?.meta?.details) {
        return []
      }

      return error.data.error.meta.details.map((detail: any) => ({
        field: detail.path ? detail.path[detail.path.length - 1] : 'unknown',
        message: detail.message.replace(/\"/g, ''),
        context: detail.context,
      }))
    case 409:
    case 422:
      if (!error?.data?.error?.meta?.errors) {
        const allErrors = error?.data?.error?.meta
        if (Object.keys(allErrors).length > 0) {
          if (allErrors?.name === 'Conflict' && allErrors?.message?.length) {
            return [
              {
                field: '',
                message: allErrors.message,
              },
            ]
          }
          return Object.entries(allErrors).map(([key, value]) => ({
            field: key,
            message: value[0],
          }))
        } else {
          return []
        }
      }

      return Object.entries(error.data.error.meta.errors).map(
        ([key, value]) => ({
          field: key,
          message: value[0],
        })
      )
    default:
      return []
  }
}

export const mapAPIErrorToFields = (
  error: FeathersError,
  setError: (key: string, value: unknown) => void
) => {
  if (!error.data?.error || typeof error.data?.error === 'undefined') {
    return
  }
  switch (error.data.error.statusCode) {
    case 412:
      error.data.error.meta.details.forEach((errorDetail) => {
        const fieldPath = errorDetail.context.label
        const fieldName = errorDetail.context.key
        const message = errorDetail.message.replace(
          `"${fieldPath}"`,
          apiFieldNameToUpperCase(fieldName)
        )
        setError(fieldName, { message })
      })
      break
    case 422:
      const details = error.data.error.meta?.errors || []
      errorConverter(details)

      Object.entries(details).forEach(([key, value]) => {
        setError(key, {
          message: `${apiFieldNameToUpperCase(key)} ${value[0].toLowerCase()}`,
        })
      })
      break
  }
}
