import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { api, PaylinkRequest, Tags } from '@shared/api'
import { preprocessCurrencyAmount } from '@shared/api/src/utils/transactions/preprocessCurrencyAmount'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  HasPermission,
  Loading,
  PageLayoutContainerBlocks,
  PageLayoutContainerMainBlocks,
  PageLayoutContainerSideBlocks,
} from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks'
import {
  formatDate,
  formatRawDate,
  mapAPIErrorToFields,
  processPhone,
} from '@shared/utils'

import { CustomerDetails } from '@/components/customer-details/CustomerDetails'

import { AdvancedInformation } from '../components/advanced-information/AdvancedInformation'
import { PaylinkInformation } from '../components/paylink-information/PaylinkInformation'
import { PaylinkSummary } from '../components/paylink-summary/PaylinkSummary'

const buildSchema = (t: TFunction) =>
  yup.object().shape(
    {
      cc_product_transaction_id: yup
        .string()
        .when('ach_product_transaction_id', {
          is: (val) => !val,
          then: (schema) =>
            schema.required(
              t?.('mfe-gateway.validations.merchant-account-required')
            ),
        }),
      ach_product_transaction_id: yup
        .string()
        .nullable()
        .when(['cc_product_transaction_id', 'paylink_action'], {
          is: (ccVal, paylinkAction) => !ccVal && paylinkAction !== 'auth-only',
          then: (schema) =>
            schema.required(
              t?.('mfe-gateway.validations.merchant-account-required')
            ),
          otherwise: (schema) => schema.nullable(),
        }),
    },
    [['cc_product_transaction_id', 'ach_product_transaction_id']]
  )

const useStyles = tss.withName('PaylinkEdit').create(() => ({
  submitButtonContainer: { marginLeft: '8px' },
}))

export default function PaylinkEdit() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { id } = useParams()

  const methods = useForm<Partial<PaylinkRequest>>({
    resolver: yupResolver(buildSchema(t)),
  })

  const { state } = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isInitialLoading, setIsInitialLoading] = useState(true)

  const getPaylink = async () => {
    setIsInitialLoading(true)
    try {
      const response = await api.service('paylinks').get(id)
      const paylink = {
        paylink_action: response.paylink_action,
        delivery_method: response.delivery_method,
        amount_due: response.amount_due / 100,
        cc_product_transaction_id: response.cc_product_transaction_id,
        ach_product_transaction_id: response.ach_product_transaction_id,
        email: response.email || undefined,
        cell_phone: response.cell_phone || undefined,
        store_token: !!response.store_token,
        store_token_title: response.store_token_title || undefined,
        expire_date: response.expire_date
          ? formatDate(response.expire_date, 'UTC')
          : undefined,
        description: response.description || undefined,
        tags: (response.tags as unknown as Tags[])?.map((tag) => tag.title),
        paylink_api_id: response.paylink_api_id || undefined,
        display_billing_fields: response.display_billing_fields,
        redirect_url_on_approve: response.redirect_url_on_approve || undefined,
        redirect_url_on_decline: response.redirect_url_on_decline || undefined,
        redirect_url_delay: response.redirect_url_delay || undefined,
        contact_id: response.contact_id,
        location_id: response.location_id,
      }

      methods.reset(paylink)
    } catch (error) {
      console.error(error)
    } finally {
      setIsInitialLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      getPaylink()
    }
  }, [id])

  const onSubmit = async (data: PaylinkRequest) => {
    setIsLoading(true)
    try {
      const payload: Partial<PaylinkRequest> = {
        ...data,
        cc_product_transaction_id: data.cc_product_transaction_id || null,
        ach_product_transaction_id: data.ach_product_transaction_id || null,
        expire_date: data.expire_date
          ? formatRawDate(data.expire_date, 'yyyy-MM-dd')
          : null,
        amount_due: preprocessCurrencyAmount(data.amount_due),
        cell_phone: data.cell_phone ? processPhone(data.cell_phone) : null,
        delivery_method: data.delivery_method,
      }
      await api.service('paylinks').patch(id, payload)

      navigate(`/merchant/gateway/paylinks/${id}/view`)
    } catch (error) {
      mapAPIErrorToFields(error, methods.setError)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.paylink.action.edit'),
      null,
      [t('common.gateway'), t('common.paylinks')],
      state?.backUrl || `/merchant/gateway/paylinks`
    )
  }, [])

  const amountDue = preprocessCurrencyAmount(methods.watch('amount_due'))
  const expireDate = methods.watch('expire_date')

  return (
    <HasPermission permission="v2.paylinks.put">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {isInitialLoading ? (
            <Loading />
          ) : (
            <PageLayoutContainerBlocks isButtonBarAtBottom>
              <PageLayoutContainerMainBlocks>
                <PaylinkInformation />
                <AdvancedInformation />
              </PageLayoutContainerMainBlocks>
              <PageLayoutContainerSideBlocks>
                <CustomerDetails
                  disableCustomer
                  hideWallet
                  customerRequired={false}
                  isWhiteBackground
                  guidingId="paylink-edit"
                />
                <PaylinkSummary
                  paylink={{
                    amount_due: amountDue,
                    expire_date: expireDate,
                  }}
                />
              </PageLayoutContainerSideBlocks>

              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      label={t('common.cancel')}
                      color={'secondary'}
                      onClick={() => {
                        navigate(state?.backUrl || '/merchant/gateway/paylinks')
                      }}
                      testId="cancel-button"
                      guidingId="paylink-edit-cancel"
                    />
                    <Button
                      type="submit"
                      label={t('common.save-changes')}
                      isLoading={isLoading}
                      testId="save-button"
                      guidingId="paylink-edit-save"
                      containerClassName={classes.submitButtonContainer}
                    />
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </PageLayoutContainerBlocks>
          )}
        </form>
      </FormProvider>
    </HasPermission>
  )
}
