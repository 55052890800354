import React from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@shared/api/src'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
} from '@shared/components'
import { formatPhoneNumber } from '@shared/utils'

export interface ContactDetailsProps {
  customer: Contact
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ customer }) => {
  const { t } = useTranslation()

  const valueEmail = customer?.email
  const valueCellPhone = customer?.cell_phone
    ? formatPhoneNumber(customer.cell_phone, customer?.cell_phone_country_code)
    : ''
  const valueHomePhone = customer?.home_phone
    ? formatPhoneNumber(customer.home_phone, customer?.home_phone_country_code)
    : ''

  return (
    <FieldGroupContainer title={t('mfe-gateway.customer.contact-details')}>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.email')}
          value={valueEmail}
          valueTestID="email-value"
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <FieldGroupViewItem
          title={t('common.phone-cell')}
          value={valueCellPhone}
          valueTestID="cell-phone-value"
        />
        <FieldGroupViewItem
          title={t('common.phone-home')}
          value={valueHomePhone}
          valueTestID="home-phone-value"
        />
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}

export default ContactDetails
