import React from 'react'

import { Grid } from '@mui/material'

export interface PageLayoutContainerBlocksProps {
  testID?: string
  testIDSpacingContainer?: string
  isButtonBarAtBottom?: boolean
  className?: string
}

export const PageLayoutContainerBlocks: React.FC<
  PageLayoutContainerBlocksProps
> = ({
  children,
  testID = 'page-layout-container',
  isButtonBarAtBottom = false,
}) => {
  return (
    <Grid
      container
      data-testid={testID}
      paddingBottom={isButtonBarAtBottom ? '80px' : '24px'}
    >
      {children}
    </Grid>
  )
}
