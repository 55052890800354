import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { api, QuickInvoice } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'

interface ResendQuickInvoiceProps {
  open: boolean
  type: 'email' | 'sms'
  quickInvoice: QuickInvoice
  onSuccess: () => void
  onClose: () => void
}

export const ResendQuickInvoice: FC<ResendQuickInvoiceProps> = ({
  open,
  type,
  quickInvoice,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [isLoading, setIsLoading] = useState(false)

  const onResend = async () => {
    try {
      setIsLoading(true)
      if (type === 'email') {
        await api.service('quick-invoices').resendEmail(quickInvoice.id)
        setNotification({
          label: t('mfe-gateway.email-resent-successfully'),
          type: 'success',
        })
      } else {
        await api.service('quick-invoices').resendSms(quickInvoice.id)
        setNotification({
          label: t('mfe-gateway.sms-resent-successfully'),
          type: 'success',
        })
      }
      onSuccess()
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <ActionModal
      open={open}
      title={
        type === 'email' ? t('common.resend-email') : t('common.resend-sms')
      }
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
          guidingId: 'quickinvoice-resend-cancel',
        },
        {
          label:
            type === 'email'
              ? t('common.resend-email')
              : t('common.resend-sms'),
          onClick: onResend,
          color: 'primary',
          isLoading: isLoading,
          guidingId: 'quickinvoice-resend-yes',
        },
      ]}
      onClose={onClose}
      guidingId="quickinvoice-resend"
    >
      <Typography sx={{ fontSize: '14px' }} variant="body2">
        {`${t('common.modal.do-you-want-to')} `}
        {type === 'email'
          ? t('mfe-gateway.quick-invoice.resend-the-email')
          : t('mfe-gateway.quick-invoice.resend-the-sms')}
        ?
      </Typography>
    </ActionModal>
  )
}
