import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { api, PaylinkRequest } from '@shared/api'
import { preprocessCurrencyAmount } from '@shared/api/src/utils/transactions/preprocessCurrencyAmount'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  HasPermission,
  PageLayoutContainerBlocks,
  PageLayoutContainerMainBlocks,
  PageLayoutContainerSideBlocks,
} from '@shared/components'
import { useFtpPortalHubCommunication, useLocations } from '@shared/hooks'
import { formatRawDate, mapAPIErrorToFields, processPhone } from '@shared/utils'

import { CustomerDetails } from '@/components/customer-details/CustomerDetails'

import { AdvancedInformation } from '../components/advanced-information/AdvancedInformation'
import { PaylinkInformation } from '../components/paylink-information/PaylinkInformation'
import { PaylinkSummary } from '../components/paylink-summary/PaylinkSummary'

const buildSchema = (t: TFunction) =>
  yup.object().shape(
    {
      amount_due: yup
        .number()
        .typeError(t('mfe-gateway.validations.paylink.amount-due-invalid'))
        .transform((value, originalValue) => {
          if (typeof originalValue === 'string') {
            const parsed = parseFloat(originalValue.replace(/,/g, ''))
            return isNaN(parsed) ? undefined : parsed
          }
          return value
        })
        .required(t('mfe-gateway.validations.paylink.amount-due-required')),
      cc_product_transaction_id: yup
        .string()
        .when('ach_product_transaction_id', {
          is: (val) => !val,
          then: (schema) =>
            schema.required(
              t?.('mfe-gateway.validations.merchant-account-required')
            ),
        }),
      ach_product_transaction_id: yup
        .string()
        .nullable()
        .when(['cc_product_transaction_id', 'paylink_action'], {
          is: (ccVal, paylinkAction) => !ccVal && paylinkAction !== 'auth-only',
          then: (schema) =>
            schema.required(
              t?.('mfe-gateway.validations.merchant-account-required')
            ),
          otherwise: (schema) => schema.nullable(),
        }),
      email: yup.string().when('delivery_method', {
        is: (val: number) => val === 1 || val == 3,
        then: (schema) =>
          schema.required(t('mfe-gateway.validations.paylink.email-required')),
      }),
      cell_phone: yup.string().when('delivery_method', {
        is: (val: number) => val === 2 || val == 3,
        then: (schema) =>
          schema.required(t('mfe-gateway.validations.paylink.phone-required')),
      }),
    },
    [['cc_product_transaction_id', 'ach_product_transaction_id']]
  )

const useStyles = tss.withName('PaylinkAdd').create(() => ({
  submitButtonContainer: { marginLeft: '8px' },
}))

export default function PaylinkAdd() {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { selectedLocation } = useLocations()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<Partial<PaylinkRequest>>({
    resolver: yupResolver(buildSchema(t)),
    defaultValues: {
      paylink_action: 'sale',
      delivery_method: 1,
      store_token: false,
      display_billing_fields: true,
      email: '',
    },
  })

  const amountDue = preprocessCurrencyAmount(methods.watch('amount_due'))
  const expireDate = methods.watch('expire_date')

  useEffect(() => {
    setAppBarTitle(
      t('mfe-gateway.paylink.action.create'),
      null,
      [t('common.gateway'), t('common.paylinks')],
      `/merchant/gateway/paylinks`
    )
  }, [])

  const onSubmit = async (data: Partial<PaylinkRequest>) => {
    setIsLoading(true)
    try {
      const payload: Partial<PaylinkRequest> = {
        ...data,

        cc_product_transaction_id: data.cc_product_transaction_id || null,
        ach_product_transaction_id: data.ach_product_transaction_id || null,
        expire_date: data.expire_date
          ? formatRawDate(data.expire_date, 'yyyy-MM-dd', 'UTC')
          : null,
        amount_due: preprocessCurrencyAmount(data.amount_due),
        cell_phone: data.cell_phone ? processPhone(data.cell_phone) : null,
        location_id: selectedLocation?.id,
      }
      const paylink = await api.service('paylinks').create(payload)
      navigate(`/merchant/gateway/paylinks/${paylink.id}/view`)
    } catch (error) {
      mapAPIErrorToFields(error, methods.setError)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <HasPermission permission="v2.paylinks.post">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PageLayoutContainerBlocks isButtonBarAtBottom>
            <PageLayoutContainerMainBlocks>
              <PaylinkInformation isAdd />
              <AdvancedInformation />
            </PageLayoutContainerMainBlocks>
            <PageLayoutContainerSideBlocks>
              <CustomerDetails
                hideWallet
                customerRequired={false}
                isWhiteBackground
                guidingId="paylink-add"
              />
              <PaylinkSummary
                paylink={{
                  amount_due: amountDue,
                  expire_date: expireDate,
                }}
              />
            </PageLayoutContainerSideBlocks>

            <AppBar
              sx={{
                bottom: 0,
                top: 'auto',
                position: 'fixed',
                boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
              }}
            >
              <ButtonBar style={{ marginBottom: '0 !important' }}>
                <ButtonBarEnd>
                  <Button
                    label={t('common.cancel')}
                    color={'secondary'}
                    onClick={() => {
                      navigate('/merchant/gateway/paylinks')
                    }}
                    testId="cancel-button"
                    guidingId="paylink-add-cancel"
                  />
                  <Button
                    type="submit"
                    label={t('mfe-gateway.paylink.action.create')}
                    isLoading={isLoading}
                    testId="save-button"
                    guidingId="paylink-add-save"
                    containerClassName={classes.submitButtonContainer}
                  />
                </ButtonBarEnd>
              </ButtonBar>
            </AppBar>
          </PageLayoutContainerBlocks>
        </form>
      </FormProvider>
    </HasPermission>
  )
}
