import React from 'react'

export const AddressIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon-pin">
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.66675V8.66675C6.89533 8.66675 6 7.77142 6 6.66675V6.66675C6 5.56208 6.89533 4.66675 8 4.66675V4.66675C9.10467 4.66675 10 5.56208 10 6.66675V6.66675C10 7.77142 9.10467 8.66675 8 8.66675Z"
        stroke="#D64123"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 14C7.99992 14 3.33325 10.1667 3.33325 6.66667C3.33325 4.08933 5.42259 2 7.99992 2C10.5773 2 12.6666 4.08933 12.6666 6.66667C12.6666 10.1667 7.99992 14 7.99992 14Z"
        stroke="#D64123"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
