import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

import { FeathersError, QuickInvoice, Transaction } from '@shared/api'
import { ActionModal, PageLayoutDivider } from '@shared/components'
import { currency, formatDate } from '@shared/utils'

const useStyles = tss
  .withName('SummaryAssignTransactionsModal')
  .create(({ theme }) => ({
    modal: {
      width: '800px',
      maxWidth: '800px',
      maxHeight: '500px',
    },
    text: {
      color: theme.palette['neutral-700'],
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Inter',
    },
    textError: {
      color: theme.palette['negative'],
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Inter',
    },
    textSuccess: {
      color: theme.palette['positive'],
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Inter',
    },
    closeModalButtonContainer: {
      width: '100% !important',
    },
  }))

export type AssignmentResult = {
  transaction: Transaction
  response: QuickInvoice
  error: FeathersError
}

interface SummaryAssignTransactionsModalProps {
  open: boolean
  assignmentResults: AssignmentResult[]
  onClose: () => void
}

export const SummaryAssignTransactionsModal: FC<
  SummaryAssignTransactionsModalProps
> = ({ open, assignmentResults, onClose }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const getError = (response: FeathersError) => {
    if (response.data?.error?.meta?.message)
      return response.data.error.meta.message
    const data = response.data?.error?.meta?.errors

    if (!data) return

    const errors = Object.keys(data).map((key) => {
      return data[key]
    })

    return errors.join(', ')
  }

  return (
    <ActionModal
      open={open}
      title={t('mfe-gateway.quick-invoice.assign-transaction-summary')}
      onClose={onClose}
      buttons={[
        {
          label: t('common.close'),
          onClick: onClose,
          containerClassName: classes.closeModalButtonContainer,
          testId: 'close-button',
          guidingId: 'quickinvoice-summaryassigntransactions-close',
        },
      ]}
      className={classes.modal}
      guidingId="quickinvoice-summaryassigntransactions"
    >
      {assignmentResults.map(({ transaction, error }, index) => (
        <Grid
          container
          columns={16}
          key={transaction.id}
          alignItems="center"
          columnGap="8px"
        >
          <Grid item xs={2} paddingX="12px">
            <Typography className={classes.text}>
              {formatDate(transaction.created_ts)}
            </Typography>
          </Grid>
          <Grid item xs={1} paddingX="12px">
            <Typography className={classes.text}>
              {currency(transaction.transaction_amount, true)}
            </Typography>
          </Grid>
          <Grid item xs={1} paddingX="12px">
            <Typography className={classes.text}>
              {transaction.last_four}
            </Typography>
          </Grid>
          <Grid item xs={3} paddingX="12px">
            <Typography className={classes.text}>
              {transaction.account_holder_name}
            </Typography>
          </Grid>
          <Grid item xs={3} paddingX="12px">
            <Typography className={classes.text}>
              {`${transaction.contact?.first_name || ''} ${
                transaction.contact?.last_name || ''
              }`}
            </Typography>
          </Grid>
          <Grid item xs={5} paddingX="12px">
            {error ? (
              <Typography className={classes.textError}>
                {getError(error)}
              </Typography>
            ) : (
              <Typography className={classes.textSuccess}>
                {t('mfe-gateway.quick-invoice.assigned-successfully')}
              </Typography>
            )}
          </Grid>
          {index !== assignmentResults.length - 1 && <PageLayoutDivider />}
        </Grid>
      ))}
    </ActionModal>
  )
}
