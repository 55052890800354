import React from 'react'
export const PhoneIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon-phone">
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4682 12.6418L13.0405 12.0694C13.431 11.6789 13.431 11.0457 13.0405 10.6552L11.7623 9.37698C11.5122 9.12693 11.1731 8.98645 10.8195 8.98645C10.4659 8.98645 10.1267 9.12693 9.87667 9.37698L8.87461 10.379C7.36956 9.81775 6.18241 8.63059 5.62112 7.12554L6.62316 6.12348C7.14386 5.60278 7.14386 4.75857 6.62316 4.23787L5.34495 2.95964C5.15741 2.77211 4.90306 2.66675 4.63784 2.66675C4.37262 2.66675 4.11827 2.77211 3.93073 2.95964L3.35836 3.532C2.91603 3.97433 2.66729 4.5741 2.66675 5.19964V5.19964C2.66488 7.35742 3.52122 9.42736 5.047 10.9531C6.57277 12.4789 8.64271 13.3353 10.8005 13.3334V13.3334C11.426 13.3329 12.0258 13.0841 12.4682 12.6418V12.6418Z"
        stroke="#D64123"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
