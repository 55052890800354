import React, { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { FieldGroupContainer, Input } from '@shared/components'

interface FormData {
  recurring_api_id: string
}

const AdvancedSettings = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<FormData>()

  const [checked, setChecked] = useState(
    getValues('recurring_api_id') ? true : false
  )

  useEffect(() => {
    if (errors.recurring_api_id) {
      setChecked(true)
    }
  }, [errors.recurring_api_id])

  return (
    <FieldGroupContainer
      title={t('common.advanced-settings')}
      showToggle={true}
      showContent={checked}
      guidingId="recurringbilling-advancedsettings"
    >
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item sm={12} md={6}>
          <Controller
            name="recurring_api_id"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="api-id-input"
                label={t('common.recurring-billing.recurring-api-id')}
                placeholder={t(
                  'mfe-gateway.recurring-billing.recurring-api-id-placeholder'
                )}
                error={!!errors.recurring_api_id}
                helperText={
                  !!errors.recurring_api_id
                    ? errors.recurring_api_id?.message
                    : t('common.leave-this-field-blank')
                }
                guidingId="recurringbilling-apiid"
              />
            )}
          />
        </Grid>
      </Grid>
    </FieldGroupContainer>
  )
}
export default AdvancedSettings
