import { IStatusPanelParams, PaginationChangedEvent } from 'ag-grid-community'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { MenuItem, Select } from '@mui/material'

import AppTheme from '@shared/design'

const useStyles = tss.withName('PageSize').create({
  container: {
    height: '1.5rem',
    width: '100%',
    marginBottom: '.75rem',
    marginLeft: '.75rem',
    display: 'flex',
    marginTop: '1rem',
  },
  span: {
    margin: '0 1rem 0 1rem',
    height: '1.5rem',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    fontWeight: 400,
  },
})

export default forwardRef((props: IStatusPanelParams, ref) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [hasData, setHasData] = useState(false)

  useEffect(() => {
    let isMounted = true

    if (!props.api) return

    const paginationEvent = (event: PaginationChangedEvent) => {
      if (isMounted && !event.api.isDestroyed()) {
        setPageSize(event.api.paginationGetPageSize())
        setCurrentPage(event.api.paginationGetCurrentPage() + 1)
        const total = event.api.paginationGetRowCount()
        setTotalCount(total)
        setHasData(total > 0)
      }
    }

    props.api.addEventListener('paginationChanged', paginationEvent)

    return () => {
      isMounted = false
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener('paginationChanged', paginationEvent)
      }
    }
  }, [props.api])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (props.api && !props.api.isDestroyed()) {
        if (props.api.getGridOption('rowModelType') === 'serverSide') {
          props.api.setGridOption('cacheBlockSize', pageSize)
        }
      }
    }, 0)
    return () => clearTimeout(timer)
  }, [pageSize, props.api])

  useEffect(() => {
    let curentPageRecordsRangeMax =
      totalCount < pageSize * currentPage ? totalCount : pageSize * currentPage
    setTotalPageCount(
      totalCount < pageSize ? totalCount : curentPageRecordsRangeMax
    )
  }, [pageSize, totalCount, currentPage])

  const options = useMemo(
    () => [
      { value: 5, label: `5 ${t('common.pagination-rows')}` },
      { value: 10, label: `10 ${t('common.pagination-rows')}` },
      { value: 15, label: `15 ${t('common.pagination-rows')}` },
      { value: 25, label: `25 ${t('common.pagination-rows')}` },
      { value: 50, label: `50 ${t('common.pagination-rows')}` },
      { value: 100, label: `100 ${t('common.pagination-rows')}` },
    ],
    [t]
  )

  useImperativeHandle(
    ref,
    () => {
      return {
        getPageSize: () => pageSize,
      }
    },
    [pageSize]
  )

  if (!hasData) {
    return null
  }

  const guidingId = `${(props as any).guidingId}-pagesize`

  return (
    <div className={classes.container}>
      <Select<number>
        value={pageSize}
        onChange={(e) => {
          if (!props.api.isDestroyed()) {
            props.api.setGridOption(
              'paginationPageSize',
              Number(e.target.value)
            )
          }
        }}
        sx={{
          borderRadius: '0.25rem',
        }}
        data-guiding-id={`${(props as any).guidingId}-pagesize`}
        data-testid="pagination-page-size-select"
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-guiding-id={`${guidingId}-${index}`}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <span
        className={classes.span}
        data-guiding-id={`${guidingId}-records`}
      >{`${t('common.records')} ${
        pageSize * currentPage - pageSize + 1
      } - ${totalPageCount} ${t('common.of')} ${totalCount}`}</span>
    </div>
  )
})
