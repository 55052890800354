import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { Input } from '@shared/components'

import { AchAccountInformation } from '@/components/ach-account-information/AchAccountInformation'
import { CreditCardInformation } from '@/components/credit-card-information/CreditCardInformation'

const useStyles = tss.withName('CCDetailsAdd').create(({ theme }) => ({
  title: {
    color: theme.palette['neutral-700'],
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 500,
  },
}))

interface DetailsAddProps {
  paymentMethod: MerchantAccount['payment_method']
}

const DetailsAdd = ({ paymentMethod }: DetailsAddProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const methods = useFormContext()
  return (
    <FormProvider {...methods}>
      <Typography className={classes.title}>
        {paymentMethod === 'cc'
          ? t('mfe-gateway.token-cc.card-details')
          : t('mfe-gateway.token-ach.account-details')}
      </Typography>

      <Controller
        name="title"
        control={methods.control}
        render={({ field }) => (
          <Input
            {...field}
            onChange={(event) => {
              field.onChange(event)
            }}
            label={t('common.title')}
            placeholder={t('common.account-title-placeholder')}
            error={!!methods.formState.errors.title}
            helperText={methods.formState.errors.title?.message?.toString()}
            style={{
              marginBottom: '24px',
            }}
            guidingId="token-title"
          />
        )}
      />

      {paymentMethod === 'cc' && (
        <CreditCardInformation showCvv={false} guidingId="token" />
      )}

      <AchAccountInformation
        guidingId="token"
        selectedMerchantAccount={{
          payment_method: paymentMethod,
        }}
      />
    </FormProvider>
  )
}

export default DetailsAdd
