import React from 'react'
export const EmailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon-email">
      <path
        id="Path"
        d="M14 6.33325L9.04905 8.45159C8.37906 8.73825 7.62094 8.73825 6.95095 8.45159L2 6.33325"
        stroke="#D64123"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        id="Rectangle"
        x="2"
        y="3.33325"
        width="12"
        height="10"
        rx="4"
        stroke="#D64123"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
