import React from 'react'

import SvgIcon from '@mui/material/SvgIcon'
export const RadioButtonFilled = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="#D64123" />
        <circle cx="10" cy="9.99133" r="6" fill="white" />
      </svg>
    </SvgIcon>
  )
}
