import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  ListItemButton,
  Typography,
} from '@mui/material'

import { Contact, Token, api } from '@shared/api'
import { Button, NoData } from '@shared/components'
import { useAuthorization, useNotification } from '@shared/hooks'
import { checkPermission } from '@shared/utils'

import { ToggleWalletActivationModal } from '@/components/toggle-wallet-activation-modal/ToggleWalletActivationModal'
import WalletAdd from '@/components/wallet/wallet-add/WalletAdd'
import WalletEdit from '@/components/wallet/wallet-edit/WalletEdit'
import WalletView from '@/components/wallet/wallet-view/WalletView'

import CustomerWalletItem from './CustomerWalletItem'

const useStyles = tss.withName('CustomerWallet').create(({ theme }) => ({
  container: {
    backgroundColor: theme.palette['neutral-100'],
    padding: '20px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    justifyContent: 'center',
    marginLeft: 'auto',
    maxWidth: '340px',
    [theme.breakpoints.down('lg')]: {
      margin: '2rem auto',
      maxWidth: '50%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  title: {
    color: theme.palette['neutral-900'],
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  walletContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  collapseButton: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

interface CustomerWalletProps {
  customer: Contact
}

const CustomerWallet = ({ customer }: CustomerWalletProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [showInactive, setShowInactive] = useState(false)
  const { userPermissionSet } = useAuthorization()
  const { setNotification } = useNotification()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [activeWallets, setActiveWallets] = useState<Token[]>([])
  const [inactiveWallets, setInactiveWallets] = useState<Token[]>([])

  const [openViewWallet, setOpenViewWallet] = useState(false)
  const [openNewWalletModal, setOpenNewWalletModal] = useState(false)
  const [openEditWalletModal, setOpenEditWalletModal] = useState(false)

  const [openWalletActivationModal, setOpenWalletActivationModal] =
    useState(false)

  const [selectedWallet, setSelectedWallet] = useState<Token | null>(null)

  const getWallets = useCallback(async () => {
    if (!customer.id) return
    try {
      setActiveWallets([])
      setInactiveWallets([])
      setLoading(true)
      setActiveWallets([])
      setInactiveWallets([])
      const wallets = await api.service('tokens').find({
        query: {
          filter: {
            active: '0,1',
            contact_id: customer.id,
          },
        },
      })
      return wallets
    } catch (error) {
      console.error(error)
    }
    return []
  }, [customer.id])

  useEffect(() => {
    let isMounted = true
    getWallets()
      .then((wallets) => {
        if (!isMounted) return
        setActiveWallets(wallets.filter((wallet) => wallet.active))
        setInactiveWallets(wallets.filter((wallet) => !wallet.active))
      })
      .finally(() => {
        if (isMounted) setLoading(false)
      })

    return () => {
      isMounted = false
    }
  }, [])

  const onEdit = (wallet: Token) => {
    setSelectedWallet(wallet)
    setOpenEditWalletModal(true)
  }

  const onView = (wallet: Token) => {
    setSelectedWallet(wallet)
    setOpenViewWallet(true)
  }

  const onAction = (wallet: Token) => {
    setSelectedWallet(wallet)
    setOpenWalletActivationModal(true)
  }

  const onCharge = (wallet: Token) => {
    navigate(`/merchant/gateway/customer/${customer.id}/charge/${wallet.id}`, {
      state: {
        backUrl: `/merchant/gateway/customer/${customer.id}/view`,
      },
    })
  }

  const onEditCompleted = () => {
    getWallets()
      .then((wallets) => {
        setActiveWallets(wallets.filter((wallet) => wallet.active))
        setInactiveWallets(wallets.filter((wallet) => !wallet.active))
      })
      .finally(() => setLoading(false))

    setOpenEditWalletModal(false)
    setSelectedWallet(null)

    setNotification({
      type: 'success',
      label: t('mfe-gateway.toast.token.updated-successfully'),
    })
  }

  const onEditError = () => {
    setNotification({
      type: 'error',
      label: t('mfe-gateway.token.error-updating-wallet'),
    })
  }

  const onAddCompleted = () => {
    getWallets()
      .then((wallets) => {
        setActiveWallets(wallets.filter((wallet) => wallet.active))
        setInactiveWallets(wallets.filter((wallet) => !wallet.active))
      })
      .finally(() => setLoading(false))

    setOpenNewWalletModal(false)

    setNotification({
      type: 'success',
      label: t('mfe-gateway.toast.token.created-successfully'),
    })
  }

  const onWalletActivationSuccess = () => {
    getWallets()
      .then((wallets) => {
        setActiveWallets(wallets.filter((wallet) => wallet.active))
        setInactiveWallets(wallets.filter((wallet) => !wallet.active))
      })
      .finally(() => setLoading(false))

    setOpenWalletActivationModal(false)
  }

  const onAddError = () => {
    setNotification({
      type: 'error',
      label: t('mfe-gateway.token.error-creating-wallet'),
    })
  }

  return (
    <>
      <WalletAdd
        open={openNewWalletModal}
        onClose={() => setOpenNewWalletModal(false)}
        customer_id={customer.id}
        onCompleted={onAddCompleted}
        onError={onAddError}
      />

      {selectedWallet && (
        <WalletView
          wallet={selectedWallet}
          open={openViewWallet}
          onClose={() => {
            setSelectedWallet(null)
            setOpenViewWallet(false)
          }}
        />
      )}

      {selectedWallet && (
        <WalletEdit
          wallet={selectedWallet}
          open={openEditWalletModal}
          onClose={() => {
            setOpenEditWalletModal(false)
            setSelectedWallet(null)
          }}
          onCompleted={onEditCompleted}
          onError={onEditError}
        />
      )}

      {selectedWallet && (
        <ToggleWalletActivationModal
          customer={customer}
          wallet={selectedWallet}
          open={openWalletActivationModal}
          onClose={() => setOpenWalletActivationModal(false)}
          onSuccess={onWalletActivationSuccess}
        />
      )}

      <Box className={classes.container}>
        <Typography className={classes.title}>{t('common.wallet')}</Typography>
        {loading && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {activeWallets.length === 0 &&
          inactiveWallets.length === 0 &&
          !loading && (
            <NoData
              title={t('mfe-gateway.token.no-wallets-found')}
              label={t('mfe-gateway.token.add-wallet-to-customer')}
              style={{
                maxHeight: '200px',
              }}
            />
          )}
        <Box className={classes.walletContainer}>
          {activeWallets.map((wallet, index) => (
            <CustomerWalletItem
              key={wallet.id}
              wallet={wallet}
              onEdit={onEdit}
              onView={onView}
              onAction={onAction}
              onCharge={onCharge}
              guidingId={`token-item-${index}`}
            />
          ))}
        </Box>

        {!!inactiveWallets.length && (
          <>
            <ListItemButton
              className={classes.collapseButton}
              onClick={() => setShowInactive(!showInactive)}
              data-guiding-id="token-showinactive"
            >
              <Typography>{t('common.show-inactive')}</Typography>
              {showInactive ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={showInactive}>
              <Box className={classes.walletContainer}>
                {inactiveWallets.map((wallet, index) => (
                  <CustomerWalletItem
                    key={wallet.id}
                    wallet={wallet}
                    onEdit={onEdit}
                    onView={onView}
                    onAction={onAction}
                    onCharge={onCharge}
                    guidingId={`token-item-${index}-inactive`}
                  />
                ))}
              </Box>
            </Collapse>
          </>
        )}

        {checkPermission(userPermissionSet, 'v2.accountvaults.post') && (
          <>
            {activeWallets.length || inactiveWallets.length ? (
              <Divider variant="middle" />
            ) : null}
            <Button
              onClick={() => setOpenNewWalletModal(true)}
              label={t('mfe-gateway.token.add-wallet')}
              color="secondary"
              guidingId="token-addwallet"
            />
          </>
        )}
      </Box>
    </>
  )
}

export default CustomerWallet
