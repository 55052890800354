import { TransactionData, Location, MerchantAccount, User } from '../../../api'
import { AccountType, ProcessMethod } from '../../../types'
import { getDefaultSECCode } from '../get-default-sec-code/getDefaultSECCode'
import { getDefaultTerminalId } from '../get-default-terminal-id/getDefaultTerminalId'

export const getDefaultPaymentDetails = (
  location: Location,
  user: User,
  merchantAccount: MerchantAccount | null,
  processMethod: ProcessMethod,
  accountType: AccountType | null
): Partial<TransactionData> => {
  // Removed processMethod !== 'manual' from the condition below, because we need to set terminal id as we do after the condition
  if (processMethod === 'wallet' || merchantAccount === undefined) {
    return {
      terminal_id: undefined,
      account_holder_name: undefined,
      account_number: undefined,
      exp_date: undefined,
      ach_sec_code: undefined,
      account_type: undefined,
      routing_number: undefined,
    }
  }
  return {
    terminal_id:
      processMethod === 'terminal'
        ? getDefaultTerminalId(location, merchantAccount, user)
        : undefined,
    account_holder_name: '',
    account_number: '',
    exp_date: '',
    cvv: '',
    account_type:
      merchantAccount?.payment_method === 'ach' ? 'checking' : undefined,
    routing_number: '',
    ach_sec_code: getDefaultSECCode(merchantAccount, accountType),
    token_id: undefined,
  }
}
