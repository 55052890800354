import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Typography, Link } from '@mui/material'

import { FolderIcon } from '@shared/icons'
const useStyles = tss
  .withName('VirtualTerminalUnavailable')
  .create(({ theme }) => ({
    root: {
      display: 'grid',
      placeContent: 'center',
      textAlign: 'center',
      height: 'calc(100vh - 126px)',
      background: 'white',
      width: '100%',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {},
    title: {
      margin: '.3em 0',
    },
    link: {
      color: theme.palette['neutral-700'],
      fontWeight: 600,
      textDecorationColor: theme.palette['neutral-700'],
      ':hover': {
        cursor: 'pointer',
      },
    },
  }))

interface VirtualTerminalUnavailableProps {
  paymentMethodUnavailable?: boolean
}

export const VirtualTerminalUnavailable: FC<
  VirtualTerminalUnavailableProps
> = ({ paymentMethodUnavailable = false }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { walletId } = useParams()

  const navigate = useNavigate()

  return (
    <Box className={classes.root}>
      <Box className={classes.iconContainer}>
        <FolderIcon className={classes.icon} alt={'wallet-icon'} />
      </Box>

      <Typography
        className={classes.title}
        variant="h4"
        data-testid="virtual-terminal-unavailable-title"
      >
        {t('mfe-gateway.virtual-terminal.unavailable')}
      </Typography>

      {paymentMethodUnavailable && (
        <>
          <Typography
            data-testid="virtual-terminal-unavailable-payment-method"
            variant="h5"
          >
            {t('mfe-gateway.virtual-terminal.unavailable-payment-method')}
          </Typography>
          <Typography
            variant="body2"
            data-testid="virtual-terminal-unavailable-payment-method-description"
          >
            <Link
              component="span"
              onClick={() =>
                navigate(window.location.pathname.split(walletId)[0])
              }
              className={classes.link}
              data-guiding-id="virtualterminal-unavailable"
            >
              {t('common.click-here')}
            </Link>{' '}
            {t('mfe-gateway.virtual-terminal.visit-generic-charge')}
          </Typography>
        </>
      )}

      {!paymentMethodUnavailable && (
        <Typography
          variant="body2"
          data-testid="virtual-terminal-unavailable-description"
        >
          {t('mfe-gateway.virtual-terminal.unavailable-explainer')}
        </Typography>
      )}
    </Box>
  )
}
