import React from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Token } from '@shared/api'
import { ActiveStatus, ContextMenu, AccountType } from '@shared/components'
import { useAuthorization } from '@shared/hooks'
import { accountTypeLabel, PaymentMethodType } from '@shared/types'
import { checkPermission } from '@shared/utils'
import { formatExpDate } from '@shared/utils/format-exp-date/formatExpDate'

const useStyles = tss.withName('CustomerWalletItem').create(({ theme }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '40px',
    maxHeight: '60px',
    [theme.breakpoints.down('lg')]: {
      gap: '8px',
    },
    [theme.breakpoints.up('xl')]: {
      gap: '12px',
    },
  },
  walletData: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  wallet: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette['neutral-900'],
    width: '100%',
  },
  subData: {
    fontSize: '12px',
    color: theme.palette['neutral-500'],
    fontWeight: 400,
    lineHeight: '20px',
  },
}))

interface CustomerWalletItemProps {
  wallet: Token
  onEdit: (wallet: Token) => void
  onView: (wallet: Token) => void
  onAction: (wallet: Token) => void
  onCharge: (wallet: Token) => void
  guidingId?: string
}
const CustomerWalletItem = ({
  wallet,
  onEdit,
  onView,
  onAction,
  onCharge,
  guidingId,
}: CustomerWalletItemProps) => {
  const { classes } = useStyles()
  const { userPermissionSet } = useAuthorization()
  const { t } = useTranslation()

  const options = [
    {
      label: t('common.view'),
      action: () => onView(wallet),
      guidingId: `${guidingId}-view`,
    },
    {
      label: t('common.edit'),
      action: () => onEdit(wallet),
      isVisible:
        wallet.active &&
        checkPermission(userPermissionSet, 'v2.accountvaults.put'),
      guidingId: `${guidingId}-edit`,
    },
    {
      label: t('common.charge'),
      action: () => onCharge(wallet),
      isVisible:
        wallet.active &&
        checkPermission(userPermissionSet, 'v2.transactions.post'),
      guidingId: `${guidingId}-charge`,
    },
    {
      label: t('common.deactivate'),
      action: () => onAction(wallet),
      isVisible:
        wallet.active &&
        checkPermission(userPermissionSet, 'v2.accountvaults.delete'),
      guidingId: `${guidingId}-deactivate`,
    },
    {
      label: t('common.reactivate'),
      action: () => onAction(wallet),
      isVisible:
        !wallet.active &&
        checkPermission(userPermissionSet, 'v2.accountvaults.post.activate'),
      guidingId: `${guidingId}-reactivate`,
    },
  ]

  return (
    <Box
      className={classes.container}
      data-guiding-id={`${guidingId}-container`}
    >
      <Box
        sx={{
          width: '32px',
        }}
      >
        <AccountType
          type={wallet.account_type}
          showLabel={false}
          style={{
            width: '32px',
          }}
        />
      </Box>
      <Box className={classes.walletData}>
        <Typography className={classes.wallet}>
          {accountTypeLabel[wallet.account_type]} • {wallet.last_four}
        </Typography>
        {wallet.payment_method == PaymentMethodType.CC && (
          <Typography className={classes.subData}>
            {`${t('common.expires')} ${formatExpDate(wallet.exp_date)}`}
          </Typography>
        )}
      </Box>
      <ActiveStatus active={wallet.active} />
      <ContextMenu
        options={options}
        iconVariant="vertical"
        guidingId={`${guidingId}-actions`}
      />
    </Box>
  )
}

export default CustomerWalletItem
