import { DateTime } from 'luxon'
import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { MerchantAccount, PaylinkRequest } from '@shared/api'
import {
  CurrencyInput,
  DatePicker,
  FieldGroupContainerBlock,
  FieldGroupRowStacked,
  Input,
  InputPhone,
  RadioButtons,
  RadioButtonsArray,
} from '@shared/components'
import { useLocations, useSub } from '@shared/hooks'

import {
  MERCHANT_ACCOUNT_ACH_CHANGE_EVENT,
  MERCHANT_ACCOUNT_CC_CHANGE_EVENT,
  MerchantSelector,
} from '@/components/merchant-selector/MerchantSelector'

interface PaylinkInformationProps {
  isAdd?: boolean
}

export const PaylinkInformation: FC<PaylinkInformationProps> = ({
  isAdd = false,
}) => {
  const { t } = useTranslation()
  const { allMerchantAccounts, selectedLocation } = useLocations()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<Partial<PaylinkRequest>>()

  const [ccMerchantAccount, setCCMerchantAccount] =
    useState<MerchantAccount | null>(null)
  const [achMerchantAccount, setACHMerchantAccount] =
    useState<MerchantAccount | null>(null)

  const deliveryMethod = watch('delivery_method')
  const storeToken = watch('store_token')
  const paylinkAction = watch('paylink_action')

  const prevPaylinkAction = useRef(paylinkAction)

  useSub<typeof MERCHANT_ACCOUNT_CC_CHANGE_EVENT>(
    MERCHANT_ACCOUNT_CC_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setCCMerchantAccount(merchantAccount)
  )

  useSub<typeof MERCHANT_ACCOUNT_ACH_CHANGE_EVENT>(
    MERCHANT_ACCOUNT_ACH_CHANGE_EVENT.type,
    ({ data: merchantAccount }) => setACHMerchantAccount(merchantAccount)
  )

  const transactionTypeButtons: RadioButtonsArray = [
    {
      title: t('common.sale'),
      onClick: () => setValue('paylink_action', 'sale'),
      color: 'secondary',
      defaultSelected: paylinkAction === 'sale',
      style: { width: '100%' },
      guidingId: 'paylink-action-sale',
    },
    {
      title: t('common.auth-only'),
      onClick: () => {
        setValue('paylink_action', 'auth-only')
        setValue('ach_product_transaction_id', null)
      },
      color: 'secondary',
      defaultSelected: paylinkAction === 'auth-only',
      style: { width: '100%' },
      guidingId: 'paylink-action-authonly',
    },
  ]

  const deliveryMethodButtons: RadioButtonsArray = [
    {
      title: t('common.email'),
      onClick: () => setValue('delivery_method', 1),
      color: 'secondary',
      defaultSelected: deliveryMethod === 1,
      style: { width: '100%' },
      guidingId: 'paylink-delivery-email',
    },
    {
      title: t('common.sms'),
      onClick: () => setValue('delivery_method', 2),
      color: 'secondary',
      defaultSelected: deliveryMethod === 2,
      style: { width: '100%' },
      visible:
        paylinkAction === 'auth-only'
          ? !!ccMerchantAccount?.sms_enable
          : !!ccMerchantAccount?.sms_enable || !!achMerchantAccount?.sms_enable, // if auth-only cannot select an ach so ach setting doesn't apply
      guidingId: 'paylink-delivery-sms',
    },
    {
      title: t('mfe-gateway.email-and-sms'),
      onClick: () => setValue('delivery_method', 3),
      color: 'secondary',
      defaultSelected: deliveryMethod === 3,
      style: { width: '100%' },
      visible:
        paylinkAction === 'auth-only'
          ? !!ccMerchantAccount?.sms_enable
          : !!ccMerchantAccount?.sms_enable || !!achMerchantAccount?.sms_enable, // if auth-only cannot select an ach so ach setting doesn't apply
      guidingId: 'paylink-delivery-emailsms',
    },
    {
      title: t('common.none'),
      onClick: () => setValue('delivery_method', 0),
      color: 'secondary',
      defaultSelected: deliveryMethod === 0,
      style: { width: '100%' },
      guidingId: 'paylink-delivery-none',
    },
  ]

  const storeTokenButtons: RadioButtonsArray = [
    {
      title: t('common.yes'),
      onClick: () => setValue('store_token', true),
      color: 'secondary',
      defaultSelected: storeToken,
      style: { width: '100%' },
      guidingId: 'paylink-storetoken-yes',
    },
    {
      title: t('common.no'),
      onClick: () => setValue('store_token', false),
      color: 'secondary',
      defaultSelected: !storeToken,
      style: { width: '100%' },
      guidingId: 'paylink-storetoken-no',
    },
  ]

  const hasCCPaylink = allMerchantAccounts.some(
    ({ paylink_allow, active, payment_method }) =>
      paylink_allow && payment_method === 'cc' && active
  )

  useEffect(() => {
    if (prevPaylinkAction.current !== paylinkAction) {
      if (paylinkAction === 'sale') {
        const defaultAch =
          allMerchantAccounts.find(
            (merchantAccount) =>
              selectedLocation?.default_ach === merchantAccount.id &&
              merchantAccount.active &&
              merchantAccount.paylink_allow
          ) ||
          allMerchantAccounts.find(
            (merchantAccount) =>
              merchantAccount.payment_method === 'ach' &&
              merchantAccount.active &&
              merchantAccount.paylink_allow
          )

        if (defaultAch) {
          setACHMerchantAccount(defaultAch)
          setValue('ach_product_transaction_id', defaultAch.id)
        }
      } else if (paylinkAction === 'auth-only') {
        setACHMerchantAccount(null)
        setValue('ach_product_transaction_id', null)
      }

      prevPaylinkAction.current = paylinkAction
    }
  }, [
    paylinkAction,
    allMerchantAccounts,
    selectedLocation,
    setValue,
    setACHMerchantAccount,
  ])

  useEffect(() => {
    if (paylinkAction === 'sale') {
      if (
        !ccMerchantAccount?.sms_enable &&
        !achMerchantAccount?.sms_enable &&
        (deliveryMethod === 2 || deliveryMethod === 3)
      ) {
        setValue('delivery_method', 1)
      }
    } else if (paylinkAction === 'auth-only') {
      if (
        !ccMerchantAccount?.sms_enable &&
        (deliveryMethod === 2 || deliveryMethod === 3)
      ) {
        setValue('delivery_method', 1)
      }
    }
  }, [
    paylinkAction,
    ccMerchantAccount?.sms_enable,
    achMerchantAccount?.sms_enable,
    deliveryMethod,
    setValue,
  ])

  return (
    <FieldGroupContainerBlock
      title={t('mfe-gateway.paylink.paylink-information')}
      guidingId="paylink-information"
    >
      {hasCCPaylink && (
        <FieldGroupRowStacked>
          <Grid item xs={6}>
            <RadioButtons
              label={t('common.transaction-type')}
              buttons={transactionTypeButtons}
              required
              guidingId="paylink-transaction-type"
            />
          </Grid>
        </FieldGroupRowStacked>
      )}

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <RadioButtons
            label={t('common.delivery-method')}
            buttons={deliveryMethodButtons}
            required
            guidingId="paylink-delivery-method"
          />
        </Grid>
      </FieldGroupRowStacked>

      <MerchantSelector
        variant="paylink"
        defaultMerchants={isAdd ? true : false}
        achSelectorProps={{
          helperText:
            paylinkAction === 'auth-only'
              ? t('mfe-gateway.paylink.ach-not-supported')
              : undefined,
          disabled: paylinkAction === 'auth-only',
        }}
      />

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="amount_due"
            control={control}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label={t('common.amount-due')}
                required
                error={!!errors.amount_due}
                helperText={errors.amount_due?.message?.toString()}
                guidingId="paylink-amountdue"
                testId="paylink-amountdue"
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>

      {deliveryMethod !== 0 && (
        <FieldGroupRowStacked spacing="24px">
          {(deliveryMethod === 1 || deliveryMethod === 3) && (
            <Grid item xs={deliveryMethod === 3 ? 6 : 12}>
              <Controller
                name="email"
                control={control}
                shouldUnregister
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('common.email')}
                    placeholder={t('common.email-placeholder')}
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                    style={{ width: '100%' }}
                    guidingId="paylink-email"
                    testId="paylink-email"
                  />
                )}
              />
            </Grid>
          )}

          {(deliveryMethod === 2 || deliveryMethod === 3) && (
            <Grid item xs={deliveryMethod === 3 ? 6 : 12}>
              <Controller
                name="cell_phone"
                control={control}
                shouldUnregister
                render={({ field }) => (
                  <InputPhone
                    {...field}
                    label={t('common.phone-cell')}
                    required
                    error={!!errors.cell_phone}
                    helperText={errors.cell_phone?.message?.toString()}
                    style={{ width: '100%' }}
                    guidingId="paylink-phone"
                  />
                )}
              />
            </Grid>
          )}
        </FieldGroupRowStacked>
      )}

      {!!selectedLocation?.product_token && (
        <FieldGroupRowStacked spacing="24px">
          <Grid item xs={6}>
            <RadioButtons
              label={t('mfe-gateway.paylink.store-token')}
              buttons={storeTokenButtons}
              guidingId="paylink-storetoken"
            />
          </Grid>
          {storeToken && (
            <Grid item xs={6}>
              <Controller
                name="store_token_title"
                control={control}
                shouldUnregister
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('mfe-gateway.paylink.token-title')}
                    placeholder={t(
                      'mfe-gateway.paylink.token-title-placeholder'
                    )}
                    error={!!errors.store_token_title}
                    helperText={errors.store_token_title?.message?.toString()}
                    guidingId="paylink-tokentitle"
                  />
                )}
              />
            </Grid>
          )}
        </FieldGroupRowStacked>
      )}

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="expire_date"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label={t('common.date-expiration')}
                style={{ width: '100%' }}
                error={!!errors.expire_date}
                helperText={
                  errors.expire_date?.message?.toString() ||
                  t('mfe-gateway.paylink.expiration-date-info')
                }
                disablePast
                maxDate={DateTime.now().plus({ years: 1 })}
                guidingId="paylink-expirationdate"
                useValueAsInitialDate
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>

      <FieldGroupRowStacked>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('common.description')}
                placeholder={t('common.description-placeholder')}
                style={{ width: '100%' }}
                error={!!errors.description}
                helperText={errors.description?.message?.toString()}
                guidingId="paylink-description"
              />
            )}
          />
        </Grid>
      </FieldGroupRowStacked>
    </FieldGroupContainerBlock>
  )
}
