import React from 'react'

import { Grid, GridOwnProps } from '@mui/material'
export interface FieldGroupRowStackedProps
  extends Omit<GridOwnProps, 'container'> {
  testID?: string
}

export const FieldGroupRowStacked: React.FC<FieldGroupRowStackedProps> = ({
  children,
  testID = 'field-group-row',
  ...props
}) => {
  return (
    <Grid container marginBottom="12px" {...props} data-testid={testID}>
      {children}
    </Grid>
  )
}
