import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { PaymentMethodType, EnumServiceName } from '@shared/types'

import {
  api,
  Location,
  MerchantAccount,
  Tags,
  Terminal,
  LocationInfo,
} from '../api'

export type LocationContextState = {
  locations: LocationInfo[]
  setLocations: (locations: LocationInfo[]) => void

  totalLocations: number
  setTotalLocations: Dispatch<SetStateAction<number>>

  locationTags: Tags[]
  setLocationTags: Dispatch<SetStateAction<Tags[]>>
  updateLocationTags(location_id: string): Promise<void>

  locationTerminals: Terminal[]
  setLocationTerminals: Dispatch<SetStateAction<Terminal[]>>
  updateLocationTerminals(location_id: string): Promise<void>

  selectedLocation: LocationInfo
  setSelectedLocation(location: LocationInfo): void

  setUserPrimaryLocationID(id: string): void

  allMerchantAccounts: MerchantAccount[]

  hasMerchantAccountACH: boolean | undefined
  hasMerchantAccountCC: boolean | undefined
  hasProductRecurringService: boolean | undefined
  hasMerchantAccountACHActive: boolean | undefined
  hasMerchantAccountCCActive: boolean | undefined
  hasRecurringActive: boolean | undefined
  hasPaylinkEnabled: boolean | undefined
  hasQuickInvoiceEnabled: boolean | undefined
  hasFileService: boolean
}

type LocationContextStateProps = {
  children: ReactNode
}

export const LocationContext = createContext<LocationContextState>(
  {} as LocationContextState
)

export const LocationProvider = ({ children }: LocationContextStateProps) => {
  const [locations, _setLocations] = useState<Location[]>([])
  const [totalLocations, setTotalLocations] = useState<number>(0)
  const [selectedLocation, _setSelectedLocation] = useState<Location>(
    {} as Location
  )

  const locationsRef = useRef<Location[]>()
  locationsRef.current = locations

  const [locationTags, setLocationTags] = useState<Tags[]>([])
  const [locationTerminals, setLocationTerminals] = useState<Terminal[]>([])
  const [userPrimaryLocationID, _setUserPrimaryLocationID] = useState<
    string | undefined
  >()

  const [allMerchantAccounts, setAllMerchantAccounts] = useState<
    MerchantAccount[]
  >([])

  const [hasMerchantAccountACH, setHasMerchantAccountACH] = useState<boolean>()

  const [hasMerchantAccountCC, setHasMerchantAccountCC] = useState<boolean>()

  const [hasPaylinkEnabled, setHasPaylinkEnabled] = useState<boolean>()
  const [hasQuickInvoiceEnabled, setHasQuickInvoiceEnabled] =
    useState<boolean>()

  const [hasProductRecurringService, setHasProductRecurringService] =
    useState<boolean>()

  const [hasFileService, setHasFileService] = useState(false)

  const [hasMerchantAccountACHActive, setHasMerchantAccountACHActive] =
    useState<boolean>()

  const [hasMerchantAccountCCActive, setHasMerchantAccountCCActive] =
    useState<boolean>()

  const [hasRecurringActive, setHasRecurringActive] = useState<boolean>()

  useEffect(() => {
    findLocation(selectedLocation.id)
  }, [selectedLocation.id])

  const getTags = useCallback(async (location_id: string) => {
    if (!location_id) return
    const tags = await api.service('tags').find({
      query: {
        filter: {
          location_id: location_id,
        },
        page: {
          size: 500,
        },
      },
    })
    setLocationTags(tags)
  }, [])

  const getTerminals = useCallback(async (location_id: string) => {
    if (!location_id) return
    const terminals = await api.service('terminals').find({
      query: {
        filter: {
          location_id: location_id,
        },
        page: {
          size: 500,
        },
      },
    })
    setLocationTerminals(terminals)
  }, [])

  const updateBooleanStates = useCallback(
    (locationInfo: Location & { product_recurring?: { active: number } }) => {
      const productRecurringService = !!locationInfo.product_recurring
      const paylinkEnabled = locationInfo.product_transactions.some((pt) =>
        Boolean(pt.paylink_allow)
      )
      const quickInvoiceEnabled = locationInfo.product_transactions.some((pt) =>
        Boolean(pt.quick_invoice_allow)
      )
      const isACHMerchantAccount = locationInfo.product_transactions.some(
        (pt) => Boolean(pt.payment_method === PaymentMethodType.ACH)
      )
      const isCCMerchantAccount = locationInfo.product_transactions.some((pt) =>
        Boolean(pt.payment_method === PaymentMethodType.CC)
      )

      const isACHMerchantAccountActive = locationInfo.product_transactions.some(
        (pt) =>
          Boolean(pt.payment_method === PaymentMethodType.ACH && pt.active)
      )
      const isCCMerchantAccountActive = locationInfo.product_transactions.some(
        (pt) => Boolean(pt.payment_method === PaymentMethodType.CC && pt.active)
      )
      const isRecurringActive = Boolean(locationInfo.product_recurring?.active)

      setAllMerchantAccounts(locationInfo.product_transactions)
      setHasFileService(!!locationInfo.product_file || false)
      setHasProductRecurringService(productRecurringService)
      setHasPaylinkEnabled(paylinkEnabled)
      setHasQuickInvoiceEnabled(quickInvoiceEnabled)
      setHasMerchantAccountACH(isACHMerchantAccount)
      setHasMerchantAccountCC(isCCMerchantAccount)
      setHasMerchantAccountACHActive(isACHMerchantAccountActive)
      setHasMerchantAccountCCActive(isCCMerchantAccountActive)
      setHasRecurringActive(isRecurringActive)
    },
    []
  )

  async function getLocationInfo(location_id: string) {
    try {
      let locationInfo = await api.service('locations').getInfo(location_id, {
        query: {
          product_transaction_active: '0,1',
        },
      })

      locationInfo = {
        ...locationInfo,
        product_transactions: locationInfo.product_transactions.filter(
          (product_transaction: MerchantAccount) =>
            product_transaction.processor !== 'zach'
        ),
      }

      updateBooleanStates(locationInfo)
      return locationInfo
    } catch (e) {
      setHasProductRecurringService(false)
      setHasPaylinkEnabled(false)
      setHasQuickInvoiceEnabled(false)
      setHasFileService(false)
      setAllMerchantAccounts([])
      setHasMerchantAccountACH(false)
      setHasMerchantAccountCC(false)
      setHasMerchantAccountACHActive(false)
      setHasMerchantAccountCCActive(false)
      setHasRecurringActive(false)
    }
  }

  const resetAllFilters = () => {
    Object.values(EnumServiceName).forEach((item) => {
      localStorage.removeItem(item)
      localStorage.removeItem(`${item}-details`)
    })
  }

  const setSelectedLocation = useCallback((location: Location) => {
    const oldLocation = localStorage.getItem('fortis:location')

    if (oldLocation !== location.id || Object.keys(location).length > 0) {
      resetAllFilters()
    }

    if (Object.keys(location).length === 0) {
      localStorage.removeItem('fortis:location')
      return
    }
    localStorage.setItem('fortis:location', location.id)

    getTags(location.id)
    getTerminals(location.id)

    location = {
      ...location,
      product_transactions: location.product_transactions.filter(
        (product_transaction: MerchantAccount) =>
          product_transaction.processor !== 'zach'
      ),
    }

    updateBooleanStates(location)
    _setSelectedLocation(location)
  }, [])

  const setLocations = (locations: Location[]) => {
    _setLocations(locations)
  }

  const setUserPrimaryLocationID = useCallback((id: string) => {
    _setUserPrimaryLocationID(id)
  }, [])

  const findLocation = useCallback(
    async (location_id: string) => {
      const currentLocation = localStorage.getItem('fortis:location')
      let oldLocationFound = true
      if (currentLocation === location_id) return

      // If location is in localStorage, we should set it as selected even if it is not in the locations array,
      // otherwise we should fall back to the user's primary location
      if (currentLocation) {
        const found = locationsRef.current.find((l) => l.id === currentLocation)
        if (found) {
          updateBooleanStates(found)
          _setSelectedLocation(found)
        } else {
          try {
            const [location] = await Promise.all([
              getLocationInfo(currentLocation),
              getTags(currentLocation),
              getTerminals(currentLocation),
            ])

            _setSelectedLocation(location)
          } catch (error) {
            oldLocationFound = false
          }
        }
      } else {
        oldLocationFound = false
      }

      if (userPrimaryLocationID && !oldLocationFound) {
        const found = locationsRef.current.find(
          (l) => l.id === userPrimaryLocationID
        )
        if (found) {
          // If we did not find the location in localStorage we should fall
          // back to users default and we make sure we persist that location id
          // to localStorage
          updateBooleanStates(found)
          setSelectedLocation(found)
        } else {
          // If we did not find the location doing a find, we should search for it on the api manually
          const [location] = await Promise.all([
            getLocationInfo(userPrimaryLocationID),
            getTags(userPrimaryLocationID),
            getTerminals(userPrimaryLocationID),
          ])

          _setSelectedLocation(location)
        }
      }
    },
    [locations]
  )

  return (
    <LocationContext.Provider
      value={{
        locations,
        setLocations,
        totalLocations,
        setTotalLocations,

        locationTags,
        setLocationTags,
        updateLocationTags: getTags,

        locationTerminals,
        setLocationTerminals,
        updateLocationTerminals: getTerminals,

        selectedLocation,
        setSelectedLocation,

        setUserPrimaryLocationID,

        allMerchantAccounts,
        hasMerchantAccountACH,
        hasMerchantAccountCC,

        hasMerchantAccountACHActive,
        hasMerchantAccountCCActive,
        hasRecurringActive,

        hasProductRecurringService,
        hasPaylinkEnabled,
        hasQuickInvoiceEnabled,
        hasFileService,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

export function useLocations(): LocationContextState {
  const context = useContext(LocationContext)

  if (!context) {
    throw new Error('useLocation must be used within an LocationProvider')
  }
  return context
}
